import Registesteps from "features/register/registesteps";
import StepDone from "features/register/stepDone";
//  Routes
import { HomePage, Login } from "routes";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import RequireAuth from "RequireAuth";
import StepSix from "features/register/stepSix";
import Rejected from "features/register/stepRejected";
import Dashboard from "features/dashboard/dashboard";
import Settings from "features/settings/settings";

import Archive from "features/archive/archive";
import Tasks from "features/tasks/tasks";
import OwnContent from "features/owncontent";
import SeekDetails from "features/register/SeekDetails";
import ForgotPassword from "features/login/forgotPassword";
import SetPassword from "features/login/setPassword";
import Register from "features/register/stepDone";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<Login />} />
        {/* {<Route path="/register" element={<Registesteps />} />} */}
        <Route path="/rejected" element={<Rejected />} />
        <Route path="/needmoredetails" element={<SeekDetails />} />
        <Route path="/needmoredetails" element={<SeekDetails />} />
        <Route path="/registerdone" element={<Register />} />

        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<SetPassword />} />

        <Route
          path="/dashboard"
          element={
            <RequireAuth>
              <Dashboard />
            </RequireAuth>
          }
        />
        <Route
          path="/payments"
          element={
            <RequireAuth>
              <Archive />
            </RequireAuth>
          }
        />
        <Route
          path="/settings"
          element={
            <RequireAuth>
              <Settings />
            </RequireAuth>
          }
        />
        <Route
          path="/tasks"
          element={
            <RequireAuth>
              <Tasks />
            </RequireAuth>
          }
        />
        <Route
          path="/owncontent"
          element={
            <RequireAuth>
              <OwnContent />
            </RequireAuth>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
