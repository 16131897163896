import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  FormControl,
  FormHelperText,
  Button,
} from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import { registerValidationSchema } from "utils/schema";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { auth } from "utils/firebase.config";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { connect } from "react-redux";

import TextInput from "common/TextInput";
import { saveRegisterData } from "store/auth/authActions";
import api from "store/api/api";

function Stepone({ handleNext, saveRegister, setconfirmationResult }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      dob: "",
      address1: "",
      address2: "",
      city: "",
      zip: "",
      mobile: "",
      is18: false,
      is15: false,
      sixty: false,
      mobile: "",
      password: "",
    },
    validateOnBlur: false,
    validationSchema: registerValidationSchema,
    onSubmit: async (values, helpers) => {
      try {
        setLoading(true);
        let response = await api.post(`/user/checkemailAndMobileAvailability`, {
          email: formik.values.email,
          mobile: formik.values.mobile?.replace(/\s/g, "").slice(-10),
        });
        if (response?.data?.data?.isAvailable) {
          onCaptchaVerify();
          const appVerifier = window.recaptchaVerifier;
          signInWithPhoneNumber(
            auth,
            formik?.values.mobile?.replace(/\s/g, ""),
            appVerifier
          )
            .then((confirmationResult) => {
              setconfirmationResult(confirmationResult);
              setLoading(false);
              saveRegister(values, 1);
              window.confirmationResult = confirmationResult;
              handleNext();
            })
            .catch((error) => {
              setLoading(false);
            });
        } else {
          setLoading(false);

          alert(response?.data?.data?.message);
        }
      } catch (err) {
        setLoading(true);
      }
    },
  });

  const onCaptchaVerify = () => {
    const recaptchaContainer = document.getElementById("recaptcha-container");
    // console.log("recaptchaContainer", recaptchaContainer)
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        recaptchaContainer,
        {
          size: "invisible",
          callback: (response) => {},
        },
        auth
      );
    }
  };
  return (
    <Grid container justifyContent={"center"} alignItems={"center"}>
      <Grid textAlign={"left"} lg={5} md={6} xs={11} sm={8}>
        <Typography variant="title">Register</Typography>
        <div className="mb-4"></div>
        <form noValidate onSubmit={formik.handleSubmit}>
          <Typography variant="label">{"Name"}</Typography>
          <Grid container justifyContent={"space-between"}>
            <Grid lg={6}>
              <TextInput
                type="text"
                placeholder="First Name"
                label=""
                name="firstname"
                onChange={formik.handleChange}
                value={formik.values.firstname}
                error={formik.touched.firstname ? formik.errors.firstname : ""}
              />
            </Grid>
            <Grid lg={5} textAlign={"right"}>
              <TextInput
                type="text"
                placeholder="Last Name"
                label=" "
                name="lastname"
                onChange={formik.handleChange}
                value={formik.values.lastname}
                error={formik.touched.lastname ? formik.errors.lastname : ""}
              />
            </Grid>
          </Grid>
          <TextInput
            type="text"
            placeholder="Your email"
            label={"Email"}
            name="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            error={formik.touched.email ? formik.errors.email : ""}
          />
          <TextInput
            type="date"
            placeholder="Your DoB"
            label={"Date of Birth"}
            name="dob"
            onChange={formik.handleChange}
            value={formik.values.dob}
            error={formik.touched.dob ? formik.errors.dob : ""}
          />
          <TextInput
            type="text"
            placeholder="Address line 1"
            label={"Address"}
            name="address1"
            onChange={formik.handleChange}
            value={formik.values.address1}
            bottomSpace
            error={formik.touched.address1 ? formik.errors.address1 : ""}
          />
          <TextInput
            type="text"
            placeholder="Address line 2"
            label={""}
            name="address2"
            onChange={formik.handleChange}
            value={formik.values.address2}
            bottomSpace
            error={formik.touched.address2 ? formik.errors.address2 : ""}
          />
          <TextInput
            type="text"
            placeholder="City"
            label={""}
            name="city"
            onChange={formik.handleChange}
            value={formik.values.city}
            bottomSpace
            error={formik.touched.city ? formik.errors.city : ""}
          />{" "}
          <TextInput
            type="text"
            placeholder="Zip"
            label={" "}
            name="zip"
            onChange={formik.handleChange}
            value={formik.values.zip}
            bottomSpace
            error={formik.touched.zip ? formik.errors.zip : ""}
          />
          <TextInput
            type="text"
            // placeholder="City"
            // label={""}
            name="city"
            // onChange={formik.handleChange}
            value={"Goa"}
            bottomSpace
            disabled
          />{" "}
          <TextInput
            type="text"
            placeholder="City"
            label={""}
            name="city"
            disabled
            //  onChange={formik.handleChange}
            value={"India"}
          />{" "}
          <TextInput
            type="password"
            placeholder="Your password"
            label={"Password"}
            name="password"
            onChange={formik.handleChange}
            value={formik.values.password}
            error={formik.touched.password ? formik.errors.password : ""}
          />
          <Typography variant="label">{"Mobile"}</Typography>
          <MuiTelInput
            defaultCountry="IN"
            fullWidth
            name="mobile"
            sx={{
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
              mt: 1,
              ".MuiInputBase-input": {
                padding: "10px",
              },
              ".MuiTelInput-IconButton": {
                marginTop: "-16px",
              },
            }}
            onChange={(e) => {
              formik.setValues((values) => ({
                ...values,
                mobile: e,
              }));
            }}
            onBlur={(e) => {
              if (
                !matchIsValidTel(formik.values.mobile) &&
                formik.values.mobile?.length > 14
              ) {
                formik.setFieldError("mobile", "Invalid Mobile Number ");
              }
            }}
            value={formik.values.mobile}
            error={formik.touched.mobile ? formik.errors.mobile : ""}
          />
          {formik?.errors?.mobile && formik.touched.mobile && (
            <FormHelperText sx={{ color: "#F04438", marginLeft: "14px" }}>
              {formik?.errors?.mobile}
            </FormHelperText>
          )}
          <Grid required sx={{ px: 4, mt: 2 }} variant="standard">
            <FormControlLabel
              required
              sx={{
                color: `rgba(17, 17, 17, 0.68)`,
              }}
              className="label"
              error={!!formik.errors.is18}
              control={
                <Checkbox
                  onChange={(e) => {
                    formik.values.is18 = e.target.checked;
                    if (e.target.checked)
                      formik.setFieldError("is18", undefined);
                  }}
                />
              }
              label="Yes, I have completed 18 years of age. "
            />
            {formik?.errors?.is18 && formik.touched.is18 && (
              <FormHelperText sx={{ color: "#F04438", marginLeft: "14px" }}>
                {formik?.errors?.is18}
              </FormHelperText>
            )}
            <FormControlLabel
              required
              className="label"
              control={
                <Checkbox
                  onChange={(e) => {
                    formik.values.is15 = e.target.checked;
                    if (e.target.checked)
                      formik.setFieldError("is15", undefined); // Clear the error if valid
                  }}
                />
              }
              label="Yes, I am a resident of Goa."
            />
            {formik?.errors?.is15 && formik.touched.is15 && (
              <FormHelperText sx={{ color: "#F04438", marginLeft: "14px" }}>
                {formik?.errors?.is15}
              </FormHelperText>
            )}
            <FormControlLabel
              className="label"
              required
              control={
                <Checkbox
                  onChange={(e) => {
                    formik.values.sixty = e.target.checked;
                    if (e.target.checked)
                      formik.setFieldError("sixty", undefined);
                  }}
                />
              }
              label="Yes, I have uploaded minimum 60 posts in the last six months."
            />
            {formik?.errors?.sixty && formik.touched.sixty && (
              <FormHelperText sx={{ color: "#F04438", marginLeft: "14px" }}>
                {formik?.errors?.sixty}
              </FormHelperText>
            )}
          </Grid>
          <LoadingButton
            loading={loading}
            fullWidth
            size="large"
            sx={{ mt: 10 }}
            type="submit"
            variant="contained"
          >
            Continue{" "}
          </LoadingButton>{" "}
        </form>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  getUser: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  saveRegister: (data, step) => dispatch(saveRegisterData(data, step)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Stepone);
