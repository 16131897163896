import React, { useEffect, useState, useRef } from "react";
import { Grid, Typography, Button, Divider, Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import { linkvalidationSchema } from "utils/schema";
import TextInput from "common/TextInput";
import { indigo } from "theme/colors";
import { updateUser } from "store/auth/authActions";
import { connect } from "react-redux";

function StepFive({ handleNext, updateUserdetails }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [loading, setLoading] = useState(false);
  const myDivRef = useRef(null);

  const formik = useFormik({
    initialValues: {
      links: Array(2).fill(""), // Initialize links array with 60 empty strings
    },
    validationSchema: linkvalidationSchema,
    onSubmit: async (values, helpers) => {
      try {
        setLoading(true);
        let { links } = values || {};
        updateUserdetails(
          {
            postLinks: links,
          },
          5,
          (e) => {
            if (!e.err) {
              handleNext();
              setLoading(false);
            } else {
              setLoading(false);

              alert(e.msg);
            }
          }
        );
      } catch (err) {}
    },
  });
  return (
    <Grid container justifyContent={"center"} alignItems={"center"}>
      <Grid textAlign={"center"} lg={10} md={10} xs={11}>
        <Typography variant="title">Add Post Links</Typography>
        <div className="mb-3"></div>
        <Typography variant="subtitle2">
          Add the links of 60 post which are uploaded in past 6 months.{" "}
        </Typography>
        <Grid textAlign={"left"} sx={{ overflowX: "hidden", mt: 2 }}>
          <Typography variant="subtitle1">Links added </Typography>
          <Box sx={{ mt: "5px" }}>
            <Typography variant="subtitle1">
              <span style={{ color: indigo.main }}>
                {" "}
                {formik.values.links?.length}
              </span>
              /60{" "}
            </Typography>
          </Box>
        </Grid>
        <form noValidate onSubmit={formik.handleSubmit}>
          <Grid
            textAlign={"left"}
            maxHeight={580}
            minHeight={400}
            ref={myDivRef}
            paddingBottom={10}
            overflow={"auto"}
            sx={{ overflowX: "hidden", mt: 4, px: 2 }}
          >
            {formik.values.links.map((link, index) => (
              <TextInput
                type="text"
                placeholder=""
                key={index}
                name={`links.${index}`}
                label={`Link ${index + 1}`}
                value={link}
                onChange={formik.handleChange}
                error={formik.touched.links ? formik.errors.links : ""}
              />
            ))}
          </Grid>
          <Grid textAlign={"right"}>
            <Button
              disabled={formik.values.links?.length == 60}
              variant="contained"
              sx={{ mt: 5 }}
              onClick={() => {
                myDivRef.current.scrollTop =
                  myDivRef.current.scrollHeight + 200;

                if (formik.values.links?.length != 60)
                  formik.setValues((values) => ({
                    ...values,
                    links: [...values.links, ""], // Add an empty string for a new link
                  }));
              }}
            >
              Add Link
            </Button>
          </Grid>
          <LoadingButton
            loading={loading}
            fullWidth
            size="large"
            sx={{ mt: 10 }}
            type="submit"
            variant="contained"
          >
            Continue{" "}
          </LoadingButton>{" "}
        </form>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  registerData: state.auth.registerData,
});

const mapDispatchToProps = (dispatch) => ({
  updateUserdetails: (data, step, callback) =>
    dispatch(updateUser(data, step, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(StepFive);
