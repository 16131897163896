import React, { useEffect } from "react";
import Header from "common/header/header";
import Footer from "common/footer";
import Background from "common/Background";
import {
  Grid,
  Typography,
  FormControl,
  FormHelperText,
  Button,
} from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import { registerValidationSchema } from "utils/schema";
import CloudUploadIcon from "@mui/icons-material/FileOpenOutlined";
import { useNavigate } from "react-router-dom";

function Register() {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const formik = useFormik({
    initialValues: {
      is18: false,
      is15: false,
      sixty: false,
    },
    validationSchema: registerValidationSchema,
    onSubmit: async (values, helpers) => {
      try {
      } catch (err) {}
    },
  });
  return (
    <div className="bg_wrapper">
      <Background>
        <div className="white-box2">
          <Grid
            container
            sx={{
              pt: { xs: "10px", md: "25px", lg: "80px" },
              pb: { xs: "10px", md: "50px", lg: "50px" },
              pr: { xs: "10px", md: "55px", lg: "100px" },
              pl: { xs: "10px", md: "40px", lg: "40px" },
              backgroundSize: "contain",
              borderRadius: `21.529px`,
              position: "relative",
            }}
            justifyContent="center"
            alignItems={"center"}
          >
            <Grid
              lg={9}
              sx={{
                mt: { xs: 5, lg: 0 },
                pl: { md: 0, lg: 10 },
              }}
            >
              <Typography variant="title">Thank’s For Registration </Typography>
              <Typography variant="subtitle1" sx={{ mt: 5 }}>
                Your application has been submitted and is being reviewed.
                Further steps will be informed over registered email and sms.
                You can also login to check your status here.
              </Typography>
              <img src="img/regiterDone.svg" style={{ marginTop: 5 }} />
            </Grid>
          </Grid>
        </div>
      </Background>
    </div>
  );
}

export default Register;
