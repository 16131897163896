import PropTypes from "prop-types";

import {
  Avatar,
  Badge,
  Box,
  IconButton,
  Stack,
  SvgIcon,
  Tooltip,
  useMediaQuery,
  Typography,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import { AccountPopover } from "./account-popover";
import { items } from "./config";
import { useLocation } from "react-router-dom";
import { usePopover } from "./use-popover";
import NotificationPopover from "./noti-popover";
import {
  getNotiCOunt,
  getNotificaitons,
  makAsRead,
} from "store/auth/authActions";
import { useEffect } from "react";
import { connect } from "react-redux";
import { logout } from "store/auth/authActions";
import BellIcon from "@heroicons/react/24/solid/BellIcon";

import { Battery30Outlined, Menu } from "@mui/icons-material";
const SIDE_NAV_WIDTH = 280;
const TOP_NAV_HEIGHT = 64;

const TopNav = (props) => {
  const {
    onNavOpen,
    logout,
    getNotifyCount,
    countNotification,
    getNotifyList,
    Notifications,
    makAsRead,
  } = props;
  const pathname = useLocation();
  useEffect(() => {
    getNotifyCount();
  }, []);
  const notiPopover = usePopover();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const accountPopover = usePopover();
  const user =
    typeof window !== "undefined"
      ? JSON.parse(localStorage.getItem("user"))
      : null;

  const active =
    items?.find((i) => pathname.pathname.includes(i.path))?.title || "";

  return (
    <>
      <Box
        component="header"
        sx={{
          backdropFilter: "blur(6px)",
          backgroundColor: (theme) =>
            alpha(theme.palette.background.default, 0.8),
          position: "sticky",
          // left: {
          //   lg: `${SIDE_NAV_WIDTH}px`,
          // },
          top: 0,
          // width: {
          //   lg: `calc(100% - ${SIDE_NAV_WIDTH}px)`,
          // },
          zIndex: (theme) => theme.zIndex.appBar,
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={2}
          sx={{
            minHeight: TOP_NAV_HEIGHT,
            px: 3,
          }}
        >
          <Stack alignItems="center" direction="row" spacing={2}>
            {!lgUp && (
              <IconButton onClick={onNavOpen}>
                <SvgIcon fontSize="small">
                  <Menu />
                </SvgIcon>
              </IconButton>
            )}
          </Stack>
          <Typography variant="title" color="#000">
            {active}
          </Typography>

          <Stack alignItems="center" direction="row" spacing={2}>
            {/* <Tooltip title="Contacts">
              <IconButton>
                <SvgIcon fontSize="small">
                  <UsersIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip> */}
            {/* <Tooltip title="Notifications">
              <IconButton>
                <Badge
                  badgeContent={4}
                  color="success"
                  variant="dot"
                >
                  <SvgIcon fontSize="small">
                    <BellIcon />
                  </SvgIcon>
                </Badge>
              </IconButton>
            </Tooltip> */}
            {
              <Tooltip title="Notifications">
                <IconButton onClick={notiPopover.handleOpen}>
                  <Badge
                    badgeContent={`${countNotification?.data || 0}`}
                    color="primary"
                  >
                    <SvgIcon fontSize={"medium"}>
                      <BellIcon />
                    </SvgIcon>
                  </Badge>
                </IconButton>
              </Tooltip>
            }
            <Avatar
              onClick={accountPopover.handleOpen}
              ref={accountPopover.anchorRef}
              sx={{
                bgcolor: "#FD884B",
                cursor: "pointer",
                height: 40,
                width: 40,
                textTransform: "uppercase",
              }}
            >
              {user?.firstName?.[0]}
            </Avatar>
            <Stack onClick={accountPopover.handleOpen}>
              <Typography
                color="text.primary"
                variant="body2"
                sx={{ textTransform: "capitalize" }}
              >
                {user?.firstName} {user?.lastName}
              </Typography>
              {/* <Typography
                color="text.secondary"
                variant="caption"
                sx={{ textTransform: "capitalize" }}
              >
                {user?.role}
              </Typography> */}
            </Stack>
          </Stack>
        </Stack>
      </Box>
      <AccountPopover
        anchorEl={accountPopover.anchorRef.current}
        open={accountPopover.open}
        onClose={accountPopover.handleClose}
        logout={logout}
      />
      {notiPopover.open && (
        <NotificationPopover
          anchorEl={notiPopover.anchorRef.current}
          open={notiPopover.open}
          onClose={notiPopover.handleClose}
          Notifications={Notifications}
          getNotificaitons={getNotifyList}
          makAsRead={makAsRead}
        />
      )}
    </>
  );
};

TopNav.propTypes = {
  onNavOpen: PropTypes.func,
};
const mapStateToProps = (state) => ({
  Notifications: state.auth.Notifications,

  countNotification: state.auth.countNotification,
  // Define mapStateToProps if you need to access state properties in your component
});
const mapDispatchToProps = (dispatch) => ({
  logout: (callback) => dispatch(logout(callback)),
  getNotifyCount: (callback) => dispatch(getNotiCOunt(callback)),
  getNotifyList: (callback) => dispatch(getNotificaitons(callback)),
  makAsRead: (params, data) => dispatch(makAsRead(params, data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(TopNav);
