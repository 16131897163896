import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  FormControl,
  FormHelperText,
  Button,
} from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import {
  documentValidationSchema,
  registerValidationSchema,
} from "utils/schema";
import {
  registerUser,
  saveRegisterData,
  updateDocuments,
} from "store/auth/authActions";

import { connect } from "react-redux";

import TextInput from "common/TextInput";
import FileTextInput from "common/FileTextInput";

function StepThree({ uploadDocuments, handleNext, registerData }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      govtIdProof: "",
      birthCertProof: "",
      residenceCertProof: "",
      check: false,
    },
    validationSchema: documentValidationSchema,
    onSubmit: async (values, helpers) => {
      try {
        setLoading(true);

        uploadDocuments(values, (e) => {
          if (!e.err) {
            handleNext();
            setLoading(false);
          } else {
            alert(e.msg);
            setLoading(false);
          }
        });
      } catch (err) {
        setLoading(false);
      }
    },
  });

  return (
    <Grid container justifyContent={"center"} alignItems={"center"}>
      <Grid textAlign={"center"} lg={5} md={7} xs={10} sm={8}>
        <Typography variant="title">Upload Documents</Typography>
        <div className="mb-3"></div>

        <form noValidate onSubmit={formik.handleSubmit}>
          <Grid textAlign={"left"}>
            <FileTextInput
              type="file"
              placeholder="Upload File"
              label={"Gov. Id Proof"}
              name="govtIdProof"
              onChange={formik}
              required
              value={formik.values.govtIdProof}
              error={
                formik.touched.govtIdProof ? formik.errors.govtIdProof : ""
              }
            />
            <FileTextInput
              type="file"
              placeholder="Upload File"
              label={"Birth Certificate"}
              name="birthCertProof"
              required
              onChange={formik}
              value={formik.values.birthCertProof}
              error={
                formik.touched.birthCertProof
                  ? formik.errors.birthCertProof
                  : ""
              }
            />
            <FileTextInput
              type="file"
              placeholder="Upload File"
              label={"Residence Certificate"}
              name="residenceCertProof"
              required
              onChange={formik}
              value={formik.values.residenceCertProof}
              error={
                formik.touched.residenceCertProof
                  ? formik.errors.residenceCertProof
                  : ""
              }
            />
          </Grid>
          <FormControl
            required
            error={formik.errors}
            sx={{ mt: 1, px: 1 }}
            variant="standard"
          >
            <FormGroup>
              <FormControlLabel
                required
                sx={{
                  color: `rgba(17, 17, 17, 0.68)`,
                  "PrivateSwitchBase-input": {
                    marginTop: "-20px",
                  },
                  ".MuiTypography-root": {
                    fontFamily: "Roboto",
                    color: `rgba(17, 17, 17, 0.48)`,
                    fontSize: "16px",
                    fontWeight: 500,
                    lineHeight: "16px",
                  },
                  textAlign: "left",
                }}
                className="label"
                error={!!formik.errors.check}
                control={
                  <Checkbox
                    sx={{ marginTop: "-20px" }}
                    onChange={(e) => {
                      formik.values.check = e.target.checked;
                      if (e.target.checked)
                        formik.setFieldError("check", undefined);
                    }}
                  />
                }
                label="I declare that information/ documents uploaded
                are true to the best of my knowledge. "
              />
              {formik?.errors?.check && formik.touched.check && (
                <FormHelperText>{formik?.errors?.check}</FormHelperText>
              )}
            </FormGroup>
          </FormControl>
          <LoadingButton
            loading={loading}
            fullWidth
            size="large"
            sx={{ mt: 10 }}
            type="submit"
            variant="contained"
          >
            Continue{" "}
          </LoadingButton>{" "}
        </form>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  registerData: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  uploadDocuments: (data, callback) =>
    dispatch(updateDocuments(data, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(StepThree);
