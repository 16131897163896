import * as React from "react";
import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { AvatarGroup, Grid, Stack, AccordionClassKey } from "@mui/material";
import { connect } from "react-redux";
import moment from "moment/moment";
import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/system";

import { useFormik } from "formik";
import TextInput from "common/TextInput";
import api from "store/api/api";
import FileTextInput from "common/FileTextInput";
import {
  contentValidationSchema,
  revisioncontentValidationSchema,
} from "utils/schema";
import FileUpload from "./FileUpload";
import { createTasks, reviseTasks } from "store/task/taskactions";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  ".MuiDialog-paper": {
    borderRadius: 0,
    minWidth: "40%",
  },
  "& .MuiDialogContent-root": {
    borderTop: `0px solid #F2F4F7`,
  },

  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function ReviseContent({
  setOpen,
  open,
  reviseTasksContent,
  getData,
  taskData,
}) {
  const [showfileloder, setshowfileloder] = React.useState(false);
  const [uploadProgress, setUploadProgress] = React.useState({});

  const [isloading, setLoading] = React.useState(false);
  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      driveLink: "",
      mediafile: "",
    },
    validateOnBlur: false,
    validationSchema: revisioncontentValidationSchema,
    onSubmit: async (values, helpers) => {
      try {
        let { title, description, driveLink } = formik.values || {};

        setshowfileloder(true);

        reviseTasksContent(
          { title, description, driveLink },
          taskData?._id,

          (res) => {
            if (!res.err) {
              if (formik.values?.mediafile)
                uploadFiles(formik.values.mediafile, taskData?._id);
              else {
                setshowfileloder(true);
                setOpen(false);
                getData();
              }
            } else {
              alert(res.msg);
              setshowfileloder(false);
            }
          }
        );
      } catch (e) {
        setshowfileloder(false);
      }
    },
  });

  const uploadFiles = async (files, id) => {
    const promises = [];
    // for (let i = 0; i < files.length; i++) {
    const formData = new FormData();
    console.log(files, id);
    formData.append("files", files);
    const promise = api.post(
      `/userContent/uploadFiles/content/${id}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress((prevProgress) => ({
            ...prevProgress,
            [0]: percentCompleted,
          }));
        },
      }
    );
    promises.push(promise);
    //}

    try {
      await Promise.all(promises);
      setshowfileloder(false);
      setOpen(false);
      getData();
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      setOpen(false);
    }
  };

  React.useEffect(() => {
    formik.resetForm();

    formik.values.title = taskData.title || "";
    formik.values.driveLink = taskData.driveLink || "";
    formik.values.description = taskData.description || "";
  }, [open]);
  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          sx={{
            fontSize: "22px",
          }}
        >
          Update Content{" "}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <form noValidate onSubmit={formik.handleSubmit}>
            <Typography variant="labelForm">{"Video Title"}</Typography>
            <TextInput
              placeholder={"Write your title here.."}
              name="title"
              onChange={formik.handleChange}
              value={formik.values.title}
              error={formik.touched.title ? formik.errors.title : ""}
            />

            <Typography variant="labelForm">{"Description"}</Typography>
            <TextInput
              placeholder={"Write your description here..."}
              multiline
              rows={4}
              name="description"
              onChange={formik.handleChange}
              value={formik.values.description}
              error={
                formik.touched.description ? formik.errors.description : ""
              }
            />
            <Typography variant="labelForm">{"Media File"}</Typography>

            <FileTextInput
              type="file"
              accept="video/*"
              placeholder="Upload File"
              name="mediafile"
              required
              bottomSpace
              onChange={formik}
              value={formik.values.mediafile}
              error={formik.touched.mediafile ? formik.errors.mediafile : ""}
            />
            <Box sx={{ marginLeft: "4px", marginBottom: 2 }}>
              <Typography variant="h7">
                {"  "} Note: All files should be at least 720p and less than 250
                MB
              </Typography>
            </Box>

            <>
              <Typography variant="labelForm">{"Drive Link"}</Typography>
              <TextInput
                placeholder={"Enter drive link of original media here..."}
                name="driveLink"
                onChange={formik.handleChange}
                value={formik.values.driveLink}
                error={formik.touched.driveLink ? formik.errors.driveLink : ""}
              />
            </>
            <Grid
              container
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isloading}
                sx={{ width: "200px", alignSelf: "center" }}
              >
                {"Save"}
              </LoadingButton>
            </Grid>
          </form>
        </DialogContent>
        <FileUpload
          uploadProgress={uploadProgress}
          files={formik.values.files}
          open={showfileloder}
          setOpen={setshowfileloder}
        />
        <DialogActions></DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  // userloading: state.users.userloading,
  //userData: state.users.userData,
});

const mapDispatchToProps = (dispatch) => ({
  reviseTasksContent: (data, id, callback) =>
    dispatch(reviseTasks(data, id, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ReviseContent);
