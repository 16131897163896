import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  FormControl,
  FormHelperText,
  Button,
  Divider,
  Stack,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import { registerValidationSchema, socialvalidationSchema } from "utils/schema";
import { connect } from "react-redux";

import TextInput from "common/TextInput";
import FileTextInput from "common/FileTextInput";
import SelectInput from "common/SelectInput";
import { indigo } from "theme/colors";
import { getCategory, updateUser } from "store/auth/authActions";
import api from "store/api/api";

function StepFour({
  handleNext,
  updateUserdetails,
  getCategorys,
  categorylist,
}) {
  useEffect(() => {
    getCategorys();
    window.scrollTo(0, 0);
  }, []);
  const [loading, setLoading] = useState(false);
  let followerR = [
    "A - 1,00,000++",
    "B - 50,000 - 99,999",
    "C - 25,000 - 49,999",
    "D - 10,000 - 24,999",
  ];
  const formik = useFormik({
    initialValues: {
      ytlink: "",
      ytfollow: "",
      twlink: "",
      twfollow: "",
      fblink: "",
      fbfollow: "",
      instalink: "",
      instafollow: "",
      category: "0-9999",
      primaryAcc: "",
      primaryFollow: "",
      file: "",
    },
    validationSchema: socialvalidationSchema,
    onSubmit: async (values, helpers) => {
      try {
        let respo = await validate();
        if (respo?.val) {
          setLoading(true);
          let {
            category,
            instafollow,
            instalink,
            fbfollow,
            fblink,
            ytfollow,
            ytlink,
            twfollow,
            twlink,
            primaryAcc,
            primaryFollow,
            file,
          } = values || {};
          let formdata = new FormData();
          formdata.append("file", file);
          let fileresponse = await api.post(
            "/user/uploadFile/socialMediaProof",
            formdata
          );
          let categoryFound = categorylist?.find((i) => i.title == category);
          updateUserdetails(
            {
              category: categoryFound?._id,
              socialMediaLinks: {
                instagram: instalink,
                facebook: fblink,
                youtube: ytlink,
                twitter: twlink,
              },
              socialMediaFollowers: {
                instagram: Number(instafollow),
                facebook: Number(fbfollow),
                youtube: Number(ytfollow),
                twitter: Number(twfollow),
              },

              highestFollower: {
                account: primaryAcc,
                followerCount: Number(primaryFollow),
              },
            },
            4,
            (e) => {
              if (!e.err) {
                handleNext();
                setLoading(false);
              } else {
                setLoading(false);

                alert(e.msg);
              }
            }
          );
        } else {
          alert(respo?.msg);
        }
      } catch (err) {
        setLoading(false);
      }
    },
  });

  const validate = () => {
    let value = "";
    if (formik.values.primaryAcc == "youtube") value = formik.values.ytfollow;
    if (formik.values.primaryAcc == "facebook") value = formik.values.fbfollow;
    if (formik.values.primaryAcc == "instagram")
      value = formik.values.instafollow;
    if (formik.values.primaryAcc == "twitter") value = formik.values.twfollow;

    if (value == formik.values.primaryFollow) {
      return {
        val: true,
      };
    }
    if (value != formik.values.primaryFollow) {
      return {
        val: false,
        msg: "Highest No of Followers doesn't match with primary account followers",
      };
    }
    return {
      val: false,
      msg: "Highest No. of Followers must be 10,000 or more",
    };
  };

  useEffect(() => {
    if (Number(formik.values.primaryFollow) >= 100000)
      formik.setFieldValue("category", followerR[0]);

    if (
      Number(formik.values.primaryFollow) >= 50000 &&
      Number(formik.values.primaryFollow) <= 99999
    )
      formik.setFieldValue("category", followerR[1]);

    if (
      Number(formik.values.primaryFollow) >= 25000 &&
      Number(formik.values.primaryFollow) <= 49999
    )
      formik.setFieldValue("category", followerR[2]);
    if (
      Number(formik.values.primaryFollow) >= 10000 &&
      Number(formik.values.primaryFollow) <= 24999
    )
      formik.setFieldValue("category", followerR[3]);
  }, [formik.values.primaryFollow]);

  useEffect(() => {
    let value = "";
    if (formik.values.primaryAcc == "youtube") value = formik.values.ytfollow;
    if (formik.values.primaryAcc == "facebook") value = formik.values.fbfollow;
    if (formik.values.primaryAcc == "instagram")
      value = formik.values.instafollow;
    if (formik.values.primaryAcc == "twitter") value = formik.values.twfollow;

    formik.setFieldValue("primaryFollow", Number(value));
  }, [formik.values.primaryAcc]);

  return (
    <Grid container justifyContent={"center"} alignItems={"center"}>
      <Grid textAlign={"center"} xs={11} lg={10} md={10}>
        <Typography variant="title">Select Category</Typography>
        <div className="mb-3"></div>
        <Typography variant="subtitle2">
          You’r Category will be selected based on highest number of followers
        </Typography>
        <Grid container justifyContent={"space-between"} sx={{ mt: 3, mb: 5 }}>
          {followerR.map((i) => (
            <Grid
              lg={2.8}
              md={2.4}
              sm={5}
              sx={{
                mb: { xs: 2 },
                backgroundColor:
                  formik.values.category == i ? "#ff9761" : "#fff",
              }}
              className="followbox"
              justifyContent={"center"}
              alignItems={"center"}
              container
            >
              <Stack>
                <Typography variant="subtitle1">{i} </Typography>
                <Typography
                  variant="subtitle3"
                  color={"#434D56"}
                  fontWeight={"400"}
                >
                  {"Followers"}{" "}
                </Typography>
              </Stack>
            </Grid>
          ))}
        </Grid>

        <form noValidate onSubmit={formik.handleSubmit}>
          <Grid textAlign={"left"}>
            <Grid container justifyContent={"space-between"}>
              <Grid md={5} lg={5}>
                <TextInput
                  type="text"
                  placeholder="Type your URL"
                  label={"Instagram"}
                  name="instalink"
                  onChange={formik.handleChange}
                  value={formik.values.instalink}
                  bottomSpace
                  error={
                    formik.touched.instalink ? formik.errors.instalink : ""
                  }
                />
                <TextInput
                  type="number"
                  placeholder="Enter Followers"
                  label={" "}
                  name="instafollow"
                  onChange={formik.handleChange}
                  value={formik.values.instafollow}
                  error={
                    formik.touched.instafollow ? formik.errors.instafollow : ""
                  }
                />
              </Grid>
              <Grid md={5} lg={5}>
                <TextInput
                  type="text"
                  placeholder="Type your URL"
                  label={"YouTube"}
                  name="ytlink"
                  bottomSpace
                  onChange={formik.handleChange}
                  value={formik.values.ytlink}
                  error={formik.touched.ytlink ? formik.errors.ytlink : ""}
                />
                <TextInput
                  type="number"
                  placeholder="Enter Followers"
                  label={" "}
                  name="ytfollow"
                  onChange={formik.handleChange}
                  value={formik.values.ytfollow}
                  error={formik.touched.ytfollow ? formik.errors.ytfollow : ""}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent={"space-between"} sx={{ mb: 5 }}>
              <Grid md={5} lg={5}>
                <TextInput
                  type="text"
                  placeholder="Type your URL"
                  label={"Facebook"}
                  name="fblink"
                  onChange={formik.handleChange}
                  value={formik.values.fblink}
                  bottomSpace
                  error={formik.touched.fblink ? formik.errors.fblink : ""}
                />
                <TextInput
                  type="number"
                  placeholder="Enter Followers"
                  label={" "}
                  name="fbfollow"
                  onChange={formik.handleChange}
                  value={formik.values.fbfollow}
                  error={formik.touched.fbfollow ? formik.errors.fbfollow : ""}
                />
              </Grid>
              <Grid md={5} lg={5}>
                <TextInput
                  type="text"
                  placeholder="Type your URL"
                  label={"Twitter"}
                  name="twlink"
                  bottomSpace
                  onChange={formik.handleChange}
                  value={formik.values.twlink}
                  error={formik.touched.twlink ? formik.errors.twlink : ""}
                />
                <TextInput
                  type="number"
                  placeholder="Enter Followers"
                  label={" "}
                  name="twfollow"
                  onChange={formik.handleChange}
                  value={formik.values.twfollow}
                  error={formik.touched.twfollow ? formik.errors.twfollow : ""}
                />
              </Grid>
            </Grid>
          </Grid>
          <hr
            style={{
              borderColor: ` rgba(167, 167, 167, 0.67)`,
              borderWidth: 2,
              marginBottom: 16,
            }}
          ></hr>
          <Typography variant="subtitle1">Primary Account</Typography>
          <Grid textAlign={"left"} sx={{ mt: 2 }}>
            <Grid container justifyContent={"space-between"}>
              <Grid md={5} lg={5}>
                <SelectInput
                  type="text"
                  placeholder="Select the account"
                  label={
                    "Select the  account which has highest No. of followers"
                  }
                  name="primaryAcc"
                  onChange={formik.handleChange}
                  value={formik.values.primaryAcc}
                  bottomSpace
                  options={[
                    {
                      value: "instagram",
                      label: "Instagram",
                    },
                    {
                      value: "youtube",
                      label: "YouTube",
                    },
                    {
                      value: "facebook",
                      label: "Facebook",
                    },
                    {
                      value: "twitter",
                      label: "Twitter",
                    },
                  ]}
                  error={
                    formik.touched.primaryAcc ? formik.errors.primaryAcc : ""
                  }
                />
              </Grid>
              <Grid md={5} lg={5} sx={{ mt: { xs: 2, md: 0 } }}>
                <TextInput
                  type="number"
                  placeholder="Enter Followers"
                  label={`Enter highest No. of followers   ${"\u00a0"}${"\u00a0"} ${"\u00a0"}${"\u00a0"}
                     ${"\u00a0"}${"\u00a0"} ${"\u00a0"}${"\u00a0"} ${"\u00a0"}${"\u00a0"}  ${"\u00a0"}${"\u00a0"} ${"\u00a0"}${"\u00a0"} ${"\u00a0"}${"\u00a0"} ${"\u00a0"}${"\u00a0"}  ${"\u00a0"}${"\u00a0"} ${"\u00a0"}${"\u00a0"}   `}
                  name="primaryFollow"
                  onChange={formik.handleChange}
                  value={formik.values.primaryFollow}
                  error={
                    formik.touched.primaryFollow
                      ? formik.errors.primaryFollow
                      : ""
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid textAlign={"center"} sx={{ mt: 2 }}>
            <Grid container justifyContent={"center"}>
              <Grid md={5} lg={5} xs={11}>
                <FileTextInput
                  type="file"
                  label={"Enter Screenshot"}
                  name="file"
                  onChange={formik}
                  value={formik.values.file}
                  bottomSpace
                  error={formik.touched.file ? formik.errors.file : ""}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container justifyContent={"center"} alignItems={"center"}>
            <Grid md={5} lg={7}>
              <LoadingButton
                loading={loading}
                fullWidth
                size="large"
                sx={{ mt: 10 }}
                type="submit"
                variant="contained"
              >
                Continue{" "}
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  registerData: state.auth.registerData,
  categorylist: state.auth.category,
});

const mapDispatchToProps = (dispatch) => ({
  updateUserdetails: (data, step, callback) =>
    dispatch(updateUser(data, step, callback)),
  getCategorys: (data, step, callback) => dispatch(getCategory(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(StepFour);
