import * as React from "react";
import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import {
  AvatarGroup,
  Grid,
  Stack,
  List,
  ListItem,
  ListItemText,
  TextField,
  FormHelperText,
} from "@mui/material";
import { connect } from "react-redux";
import moment from "moment/moment";
import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import FileTextInput from "common/FileTextInput";

import { addInvoice, createTasks, updateTask } from "store/task/taskactions";
import { Download, LinkOutlined } from "@mui/icons-material";
import { Card, InputAdornment, OutlinedInput, SvgIcon } from "@mui/material";
import { indigo } from "theme/colors";
import api from "store/api/api";
import { proofvalidationSchema } from "utils/schema";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  ".MuiDialog-paper": {
    borderRadius: 0,
    minWidth: "35%",
  },
  "& .MuiDialogContent-root": {
    borderTop: `0px solid #F2F4F7`,
  },

  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function ConfirmPop({
  setOpen,
  open,
  data,
  assignedTask,
  amount,
  invoiceId,
  updateTaskstatus,
  addInvoice,
  getData,
  view,
}) {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      setOpen(false);
      setActiveStep(0);
    }
  };
  const formik = useFormik({
    initialValues: {
      files: "",
    },
    validateOnBlur: false,
    validationSchema: proofvalidationSchema,
    onSubmit: async (values, helpers) => {
      const promises = [],
        receipts = [];
      const formData = new FormData();

      formData.append("files", values.files);

      const promise = api.post(
        `/invoice/uploadFiles/invoice/${assignedTask}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        }
      );
      promises.push(promise);

      try {
        let receiptValues = await Promise.all(promises);
        receiptValues?.map((items) => {
          if (items?.status == 200) receipts.push(items?.data?.data?.[0]);
        });

        addInvoice(
          {
            assignedTask,
            documents: receipts,
          },
          () => {
            handleClose();
            getData();
          }
        );
      } catch (error) {
        console.error("Error uploading files:", error);
      }
    },
  });

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          sx={{
            fontSize: "22px",
          }}
        >
          {view ? "View Details" : "Confirmation Required"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <form noValidate onSubmit={formik.handleSubmit}>
            {!view && (
              <Typography variant="smallcontent" gutterBottom>
                Are you sure you want to raise this invoice?
              </Typography>
            )}
            <Stack
              direction={"row"}
              mt={view ? 0 : 1}
              justifyContent={"flex-start"}
            >
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  color={"#636E72"}
                  sx={{
                    fontWeight: "700",
                    fontSize: "12px",
                    marginLeft: "5px",
                  }}
                >
                  Amount: {amount}
                </Typography>
              </div>
            </Stack>
            {view && (
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  color={"#636E72"}
                  sx={{
                    fontWeight: "700",
                    fontSize: "12px",
                    marginLeft: "5px",
                  }}
                >
                  Receipt Id: {invoiceId}
                </Typography>
              </div>
            )}
            {view && data?.invoice?.receipts?.[0]?.original && (
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  window.open(
                    `${process.env.REACT_APP_IMG}${data?.invoice?.receipts?.[0]?.original}`
                  );
                }}
              >
                <Typography
                  color={"#636E72"}
                  sx={{
                    fontWeight: "700",
                    fontSize: "12px",
                    marginLeft: "5px",
                  }}
                >
                  View Receipt
                </Typography>
              </div>
            )}

            {!view && (
              <Typography variant="labelForm">{"Select invoice"}</Typography>
            )}
            {!view && (
              <FileTextInput
                type="file"
                placeholder="Upload File"
                name="files"
                required
                onChange={formik}
                value={formik.values.files}
                error={formik.touched.files ? formik.errors.files : ""}
              />
            )}
            {!view && (
              <Grid
                container
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                mt={4}
              >
                <LoadingButton
                  type="submit"
                  sx={{ width: "100px", alignSelf: "center", mr: 3 }}
                  variant={"contained"}
                >
                  Yes
                </LoadingButton>
                <LoadingButton
                  onClick={handleClose}
                  sx={{ width: "100px", alignSelf: "center" }}
                  variant={"outlined"}
                >
                  No
                </LoadingButton>
              </Grid>
            )}
          </form>
        </DialogContent>

        <DialogActions></DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  addInvoice: (data, callback) => dispatch(addInvoice(data, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPop);
