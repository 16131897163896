import React, { useEffect } from "react";
import Header from "common/header/header";
import Footer from "common/footer";
import Background from "common/Background";
import {
  Grid,
  Typography,
  FormControl,
  FormHelperText,
  Button,
} from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import { registerValidationSchema } from "utils/schema";
import CloudUploadIcon from "@mui/icons-material/FileOpenOutlined";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function Rejected() {
  const navigate = useNavigate();
  const userData = useSelector((i) => i?.auth?.userData);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg_wrapper">
      <Background>
        <div className="white-box2">
          <Grid
            container
            sx={{
              pt: { xs: "10px", md: "25px", lg: "60px" },
              pb: { xs: "10px", md: "50px", lg: "50px" },
              pr: { xs: "10px", md: "55px", lg: "100px" },
              pl: { xs: "10px", md: "40px", lg: "40px" },
              backgroundSize: "contain",
              borderRadius: `21.529px`,
              position: "relative",
            }}
            justifyContent="center"
            alignItems={"center"}
          >
            <Grid
              lg={9}
              sx={{
                mt: { xs: 5, lg: 0 },
                pl: { md: 0, lg: 10 },
                minHeight: "50vh",
              }}
            >
              <Typography variant="title" gutterBottom>
                Rejected{" "}
              </Typography>
              <Grid textAlign={"left"} mt={5}>
                <Typography
                  variant="subtitle2"
                  sx={{ mt: 1 }}
                  gutterBottom
                ></Typography>
                <Typography variant="subtitle2" sx={{ mt: 1 }} gutterBottom>
                  Your application has been Rejected.{" "}
                </Typography>

                <Typography variant="subtitle2" sx={{ mt: 3 }}>
                  Reason: {userData?.reasonToReject}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Background>
    </div>
  );
}

export default Rejected;
