import { Card, Grid } from "@mui/material";
import React from "react";

function Background({ children }) {
  return (
    <div className="background-out">
      <div className="background-container">
        <img
          src="img/bgimg.svg"
          alt="Background"
          className="background-image"
        />
        <div className="content-container">
          <div className="logo-container">
            <img src="img/logo.svg" className="logo-image" />
          </div>
          {children}
        </div>
      </div>
    </div>
  );
}

export default Background;
