// taskSlice.js
import { createSlice } from "@reduxjs/toolkit";

const taskSlice = createSlice({
  name: "task",
  initialState: {
    taskList: null,
    loading: false,
    errors: null,
    taskcount: {},
    taskData: [],
  },
  reducers: {
    taskListTriggered: (state, action) => {
      state.loading = true;
    },
    taskListhSuccess: (state, action) => {
      state.taskList = action.payload;
      state.loading = false;
    },

    assigntaskListTriggered: (state, action) => {
      state.atloading = true;
    },
    assigntaskListSuccess: (state, action) => {
      state.assigntaskList = action.payload;
      state.atloading = false;
    },
    taskListFailure: (state, action) => {
      state.errors = action.payload;
      state.loading = false;
    },
    taskCountTriggered: (state, action) => {
      state.countloading = true;
    },
    taskCountSuccess: (state, action) => {
      state.taskcount = action.payload;
      state.countloading = false;
    },

    taskDataTriggered: (state, action) => {
      state.taskloading = true;
    },
    taskDataSuccess: (state, action) => {
      state.taskData = action.payload;
      state.taskloading = false;
    },

    categotyDataSuccess: (state, action) => {
      state.category = action.payload;
      state.taskloading = false;
    },
    taskbyidTriggered: (state, action) => {
      state.taskloading = true;
    },
    taskbyidSuccess: (state, action) => {
      state.taskloading = false;
      state.taskbyid = action.payload;
    },

    contentTriggered: (state, action) => {
      state.contentloading = true;
    },
    contentSuccess: (state, action) => {
      state.contentloading = false;
      state.content = action.payload;
    },
    psgetTriggered: (state, action) => {
      state.psloading = true;
    },
    psSuccess: (state, action) => {
      state.psloading = false;
      state.pslist = action.payload;
    },
    accountTrigger: (state, action) => {
      state.accountloading = true;
    },
    accountlistSuccess: (state, action) => {
      console.log("accountlistSuccess", action);
      state.accountloading = false;
      state.accountlist = action.payload;
    },
  },
});

export const {
  taskListFailure,
  taskListTriggered,
  taskListhSuccess,
  taskCountTriggered,
  taskCountSuccess,
  taskDataSuccess,
  taskDataTriggered,
  categotyDataSuccess,
  assigntaskListTriggered,
  assigntaskListSuccess,
  contentTriggered,
  contentSuccess,
  psSuccess,
  psgetTriggered,
  accountTrigger,
  accountlistSuccess,
} = taskSlice.actions;
export default taskSlice.reducer;
