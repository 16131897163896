import { Navigate, useLocation } from "react-router-dom";

function RequireAuth({ children }) {
  let auth = localStorage.getItem("auth");
  const isAuthenticated = auth || false; // Determine if the user is authenticated
  let location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}

export default RequireAuth;
