import api from "store/api/api";

export const LOGIN = (credentials) => api.post("/auth/user", credentials);
export const REGISTER = (credentials) => api.post("/user", credentials);
export const UPLOADFILE = (typelabel, data) =>
  api.post(`/user/uploadFile/${typelabel}`, data);
export const GETUSERDETAILS = () => api.get("/user");
export const GETCATE = () => api.get("/category");
export const GETMYDETAILS = () => api.get("/user/me");
export const GETMYCOUNT = () => api.get("/dashboard/user/count");

export const GETUSERBYID = (data) => api.get(`/user/${data}`);
export const UPDATE_Payment_Detail = (data) =>
  api.put(`/user/paymentDetail`, data);

export const UPDATE_USER = (data) => api.put(`/user`, data);
export const VERIFY_PHONE_NUMBER = (data) =>
  api.get(`/auth/customer/checkMobileAvailability/${data}`);
export const forgotPassword = (credentials) =>
  api.post("/auth/forgotPassword", credentials);

export const verifyResetPasswordCode = (credentials) =>
  api.post("/auth/verifyResetPasswordCode", credentials);
export const resetPassword = (credentials, id) =>
  api.post(`/auth/resetPassword/${id}`, credentials);

export const GET_NOTI_COUNT = (params) =>
  api.get(`/notification/count`, { params });
export const GET_NOTI_LIST = (params) => api.get(`/notification`, { params });

export const UPDATE_NOTI = (data) => {
  return api.post(`/notification/makAsRead`, data);
};
