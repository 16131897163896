import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyA6oPa6q_uTNjgYUH6rAMwWN5sot0AmDMc",
  authDomain: "esginf-e8015.firebaseapp.com",
  projectId: "esginf-e8015",
  storageBucket: "esginf-e8015.appspot.com",
  messagingSenderId: "626282021618",
  appId: "1:626282021618:web:761b9f6588beac96d9466b",
  measurementId: "G-4EM2F1W8H6",
  appVerificationDisabledForTesting: true,
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
// let auth = firebase.auth();
// export { auth, firebase };
