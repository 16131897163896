import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  FormControl,
  FormHelperText,
  Button,
  Divider,
  Box,
  Stack,
  Table,
  TableRow,
  TableCell,
  TableContainer,
} from "@mui/material";
import Paper from "@mui/material/Paper";

import { useNavigate } from "react-router-dom";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import { updateUser } from "store/auth/authActions";
import { connect } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
function StepSix({ handleNext, updateUserdetails }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const formik = useFormik({
    initialValues: {
      check: false,
    },
    onSubmit: async (values, helpers) => {
      try {
        setLoading(true);
        let { check } = values || {};
        if (check) {
          updateUserdetails(
            {
              isProfileComplete: check,
            },
            6,
            (e) => {
              if (!e.err) {
                setOpen(true);
                setLoading(false);
              } else {
                setLoading(false);
                alert(e.msg);
              }
            }
          );
        } else {
          formik.setFieldError(
            "check",
            "You must confirm that you have read and accept the terms and conditions and privacy policy. "
          );
          setLoading(false);
        }
      } catch (err) {}
    },
  });
  const navigate = useNavigate();

  return (
    <Grid container justifyContent={"center"} alignItems={"center"}>
      <Grid textAlign={"center"} lg={11} md={10} sm={10} xs={11}>
        <Dialog
          open={open}
          onClose={() => {
            navigate("/");
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Registration Successfull"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Congratulations, your empanelment application has been
              successfully submitted. Our team will review your application, and
              will contact for further updates .
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                navigate("/");
              }}
              autoFocus
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <Typography variant="title">Terms and Conditions</Typography>
        <form noValidate onSubmit={formik.handleSubmit}>
          <Grid textAlign={"left"} sx={{ mt: 2 }}>
            <Typography variant="subtitle1">Your Agreement</Typography>
          </Grid>
          <Grid
            textAlign={"left"}
            maxHeight={500}
            minHeight={400}
            overflow={"auto"}
            sx={{ overflowX: "hidden", mt: 2, mb: 4, pr: 2 }}
          >
            <Typography variant="content">
              <Typography variant="h6" gutterBottom>
                {" "}
                GENERAL INSTRUCTIONS
              </Typography>
              <ol className="mt-3">
                <li>
                  The Applicants shall submit/ upload their applications latest
                  by 5.00 PM on or before 14/02/2024 and any application
                  received after the specified time and date will be summarily
                  rejected. Applicants are encouraged not to wait for the last
                  date of submission of the Applications as the scrutiny of the
                  Applications will commence no sooner the Applications are
                  received by the Authority
                </li>
                <li>
                  The Applicant shall have a public social media account.
                  Private accounts will not be eligible for consideration.
                </li>
                <li>
                  The Applicants shall submit only one proposal i.e. under a
                  single category. If the Applicant submits or participates in
                  more than one category, all such proposals shall be
                  disqualified.
                </li>
                <li>
                  The Applicant whose Social Media handle/profile contains
                  offensive, abusive, discriminatory or inappropriate content
                  will be rejected without further notice or explanation. This
                  includes but is not limited to any material that promotes
                  hatred, violence, racism, sexism, discrimination, harassment,
                  or any content that is considered harmful or disrespectful to
                  individuals or groups based on their race, ethnicity, gender,
                  sexual orientation, religion, or any other characteristic
                </li>
                <li>
                  If the applicant is finally selected by the Authority, then a
                  letter of empanelment would be issued in favour of the
                  Shortlisted Applicant. The Letter of empanelment will be an
                  acceptance of the Shortlisted Applicant’s Application/proposal
                  on the terms and conditions set out in the present Request for
                  Proposal.
                </li>
                <li>
                  The Shortlisted Applicant shall perform the services and carry
                  out its obligations under the contract with due diligence and
                  efficiency, in accordance with generally accepted techniques
                  and practices used in the industry
                </li>
                <li>
                  The Authority will shortlist applicants, in accordance with
                  the method of selection specified in this document.
                </li>
                <li>
                  The Applicants shall be deemed to have understood and agreed
                  that no explanation or justification for any aspect of the
                  Selection Process will be given and that the Authority’s
                  decisions are final and binding.
                </li>
                <li>
                  The Applicants shall submit their proposals/Applications upon
                  a careful consideration of the terms and conditions contained
                  in the present Request for Proposal and upon being satisfied
                  with the same. By submitting its proposal/Application, the
                  Applicant accepts the terms and conditions contained herein.
                </li>
                <li>
                  The entire process for making an application for empanelment
                  is an online process. The APPLICANTS are requested to submit
                  the proposal in English language and strictly as per the
                  fields made available on the portal. The committee constituted
                  by the Authority will evaluate/ scrutinize only those
                  applications that are received online and complete in all
                  respects.
                </li>
                <li>
                  Upon receiving approval from the Authority, the shortlisted
                  Applicant shall ensure that the approved post remains publicly
                  visible on the designated social media handle, until any
                  further instructions are received from the Authority.
                </li>
                <li>
                  The Shortlisted Applicant shall not delete, hide, or alter the
                  approved post's content, caption, or associated hashtags
                  without prior approval from the Authority.
                </li>

                <li>
                  An Applicant upon being shortlisted shall not post on its
                  social media handle/profile any offensive, abusive,
                  discriminatory and/or inappropriate content. This includes but
                  is not limited to any material that promotes hatred, violence,
                  racism, sexism, discrimination, harassment, or any content
                  that is considered harmful or disrespectful to individuals or
                  groups based on their race, ethnicity, gender, sexual
                  orientation, religion or any other characteristic. Posting of
                  such content shall result in termination of the Contract
                  between the Shortlisted Applicant and the Authority. The
                  decision of the Authority as to what content comes within the
                  purview of this clause will be final and binding.
                </li>

                <li>
                  The Shortlisted Applicant should maintain total
                  confidentiality of all the information provided by the
                  Authority. All such information shall be the property of the
                  Authority. Upon termination of the Contract or the expiry of
                  the duration of the contract with the Shortlisted Applicant,
                  the Shortlisted Applicants obligation of confidentiality shall
                  not cease.
                </li>

                <li>
                  <strong>Duration of Assignment:</strong>
                  The contract will be initially for a period of one year and on
                  satisfactory performance the term may be extended further for
                  a period of Two years at the sole discretion of the Authority
                  and as per such evaluation process as may defined by the
                  Authority.
                </li>
                <li>
                  For any dispute, the Authority shall be the final authority
                  and its decision shall be final and binding.
                </li>
                <li>
                  The issue of this RFP does not imply that the Authority is
                  bound to select an Applicant and the Authority reserves all
                  rights to accept or reject or cancel any or all applications
                  without assigning any reason thereof. The Authority reserves
                  the right not to proceed with the empanelment.
                </li>
                <li>
                  Any update regarding the RFP shall be uploaded only on the
                  Authority’s website.
                </li>
                <li>
                  The relationship between the Shortlisted Applicant and the
                  Authority shall always be on a ‘principal to principal’ basis.
                  Nothing contained herein shall be construed as
                  constituting/establishing or creating a joint venture,
                  partnership, pooling arrangement, or any other form of
                  business arrangement or organization between the parties. The
                  rights and obligations of the parties are only those as
                  expressly set forth in this RFP. The Shortlisted Applicant
                  shall act as an independent contractor and not as an agent or
                  partner of the Authority with third parties for any purpose
                  whatsoever, and neither party shall have the authority to bind
                  the other party or make any commitments of any kind for or on
                  behalf of the other party except as specifically provided
                  herein. Each party shall be solely responsible as a principal,
                  for all their respective liabilities and payments related to
                  its employees, taxes, insurance premium, compensation,
                  financial obligations, and all other obligations, legal
                  requirements under the State as well as Central Government
                  etc.
                </li>
                <li>
                  The Authority may also terminate the Contract if the
                  Shortlisted Applicant fails to meet its obligations.
                </li>
                <li>
                  In the event that the Shortlisted Applicant receives any
                  feedback or requests from their audience or followers
                  regarding the approved post, the Shortlisted Applicant shall
                  promptly forward such communications to the Authority. The
                  Shortlisted Applicant shall not respond or take action
                  independently without consulting the Authority. Breach of this
                  condition will result in termination of the Contract with the
                  Shortlisted Applicant.
                </li>
                <li>
                  If the Shortlisted Applicant identifies any errors, omissions,
                  or inaccuracies in an approved post, they shall immediately
                  notify the Authority for any necessary modifications or edits.
                  The Shortlisted Applicant shall not make any alterations to
                  the content or messaging of the approved post without prior
                  approval from the Authority.
                </li>
                <li>
                  During the contract period, the Shortlisted Applicant agrees
                  to maintain their social media account as public without any
                  modifications to the account's privacy settings. The account
                  should remain accessible and viewable to the general public
                  for the entire duration of the contract.
                </li>
                <li>
                  The influencer shall comply with all the terms and conditions
                  set forth by the respective social media platform(s) which
                  they use. This includes adhering to community guidelines,
                  copyright regulations, and other applicable policies.
                </li>
                <li>
                  The Applicant accepts and acknowledges that the Authority does
                  not guarantee any minimum or maximum amount of work or
                  assignments to the Shortlisted Applicants during the contract
                  period. An Applicant upon being shortlisted and issued a
                  Letter of Empanelment by the Authority does not create any
                  contractual obligation on the part of the Authority to offer
                  or allocate work. The Authority reserves the right to engage
                  the Shortlisted Applicants based on the need, budget
                  constraints, and other factors as applicable, without any
                  obligation to offer work to all the Shortlisted Applicants.
                </li>
                <li>
                  The Shortlisted Applicant shall refrain from posting content
                  or engaging in activities that may contradict and/or cast
                  doubt and/or negatively affect the public perception of the
                  content of the posts provided & approved by the Authority.
                </li>
                <li>
                  Any question, disputes and/or differences in opinion arising
                  under or out of or in any way relating to the terms and
                  conditions contained in the RFP and the Contract, arising
                  between the Authority and the Shortlisted Bidder shall be
                  resolved through mutual discussions and negotiations. If a
                  resolution cannot be reached, the dispute shall be referred to
                  the CEO of the Authority and the decision of the CEO shall be
                  final and binding on the parties. If the Authority is
                  resultantly directed to pay interest to the Shortlisted
                  Bidder, the same shall be in the nature of simple interest to
                  the maximum extent of 8% per annum. The liability to pay the
                  interest shall commence from the date of the decision of the
                  CEO of the Authority. The Shortlisted Bidder herein
                  acknowledges that it shall not raise any objection to such
                  reference on the ground that the CEO of the Authority is a
                  Government servant and that he/she has dealt with the matter
                  to which the RFP relates or that in the course of his/her
                  duties as such Government servant, he/she had expressed his
                  views on all or any of the matters in dispute or difference.
                </li>
                <li>
                  This policy shall be governed by and interpreted in accordance
                  with the laws of the Government of Goa.
                </li>
                <li>
                  <strong>Payment/Remuneration:</strong> The remuneration for
                  the Shortlisted Applicants will be based on the category (A,
                  B, C, or D) they are shortlisted in.
                </li>
                <li>
                  <strong>Amendments and Termination:</strong>
                  <br />
                  a.The Authority reserves the right to amend or modify any
                  terms and conditions or scope specified in this document and
                  the letter of empanelment.
                  <br />
                  b. The engagement of the Shortlisted Applicants may be
                  terminated if they fail to comply with the guidelines, breach
                  any of the terms of this RFP or the agreement, or if the
                  Shortlisted Applicant either directly or indirectly engages in
                  unethical practices, and/or harms the reputation of the
                  government
                </li>
                <li>
                  <strong>Confidentiality and Non-Disclosure:</strong>
                  <br />
                  a. All information furnished by the Authority in pursuance of
                  the empanelment of the Shortlisted Applicant shall be deemed
                  to be confidential except for information that is approved for
                  uploading on the social media platform of the Shortlisted
                  Applicant. The Shortlisted Applicants must maintain the
                  confidentiality of all information, in any form, received from
                  the Authority during their collaboration as well as after the
                  expiry of the duration of the Contract or upon the termination
                  of the contract.
                  <br />
                  b. The Shortlisted Applicant accepts and acknowledges that the
                  Shortlisted Applicant’s breach of this Contract will cause
                  irreparable harm to the Authority and the Government of Goa.
                </li>
              </ol>
              <Typography variant="h6" gutterBottom>
                {" "}
                ELIGIBILITY CRITERIA
              </Typography>

              <ol>
                <li>
                  The Applicant shall have presence on either Facebook,
                  Instagram, YouTube or Twitter for minimum period of one year.
                  The Highest count of followers on any one of these platforms
                  will be considered for determining the influencer's category.
                </li>
                <li>
                  The shortlisting of the Applicants will be determined by
                  highest count of followers on any one of the Social Media
                  platforms as detailed below:
                  <ul>
                    <li>
                      a) Category A - 1,00,000 and above followers/subscribers
                    </li>
                    <li>
                      b) Category B - 50,000 to 99,999 followers/subscribers
                    </li>
                    <li>
                      c) Category C - 25,000 to 49,999 followers/subscribers
                    </li>
                    <li>
                      d) Category D - 10,000 to 24,999 followers/subscribers
                    </li>
                  </ul>
                </li>
                <li>
                  Only profiles of individual persons will be considered for
                  empanelment. Pages or non-individual accounts such as accounts
                  of a company, groups etc. will not be taken into consideration
                  except for YouTube channels owned by individuals.
                </li>
                <li>
                  Profiles should demonstrate active engagement, with a minimum
                  of 60 posts in the last six months.
                </li>
                <li>
                  Applicant must be a resident of Goa and provide a
                  domicile/residence certificate of 15 years.
                </li>
                <li>
                  The Applicant should have completed 18 years of age at the
                  time of submitting his/her application.
                </li>
              </ol>

              <Typography variant="h6" gutterBottom>
                Application Process:
              </Typography>

              <ol>
                <li>
                  Applicant must provide information regarding their social
                  media handles, number of followers/subscribers, and proof of
                  Goan domicile while applying for the Empanelment in prescribed
                  format.
                </li>
                <li>
                  Applicant must provide proof regarding their 60 social media
                  posts in the last 6 months.
                </li>
                <li>
                  Application/proposal for empanelment shall be submitted online
                  through a designated portal provided by the Authority.
                </li>
              </ol>

              <Typography variant="h6" gutterBottom>
                Selection Process:
              </Typography>

              <ol>
                <li>
                  The Scrutiny Committee will comprise of a Minimum 5 Member
                  Team nominated by the Authority.
                </li>
                <li>
                  Once the applications/proposals are submitted, the scrutiny
                  committee formulated by the Authority will review the
                  applications based on the eligibility criteria as defined, to
                  shortlist eligible candidates. The Scrutiny Committee shall
                  not wait for the last date of submissions in order to commence
                  the scrutiny of the Applications. The Scrutiny Committee will
                  commence the scrutiny of the Applications no sooner they are
                  received by the Authority.
                </li>
              </ol>

              <Typography variant="h6" gutterBottom>
                SCOPE OF THE WORK:
              </Typography>

              <ol>
                <li>
                  All the Shortlisted Applicants in consultation with the
                  Authority shall perform the following tasks, but not limited
                  to.
                </li>
                <li>
                  The Shortlisted Applicants will be provided with ready content
                  by the Authority. The content will be either Graphic or in
                  Audio Visual Format.
                </li>

                <li>
                  The content must be posted on all the platforms, i.e.,
                  Facebook, Instagram, Twitter and YouTube within the stipulated
                  timeframe as intimated by the Authority.
                </li>
                <li>
                  The Shortlisted Applicants may also create their own content
                  in consultation with the Authority and release the same after
                  it is approved by the Authority, in writing. If the proposed
                  content is not approved, in writing, then the Authority is not
                  liable for releasing any payment.
                </li>
                <li>
                  The content once posted shall not be deleted without the
                  written approval of the Authority, during the contract period.
                </li>
                <li>
                  The Shortlisted Applicants must adhere to strict policy of not
                  posting any obscene or offensive content on their Social Media
                  Platforms.
                </li>
                <li>
                  The Shortlisted Applicants shall not refuse to air any content
                  released by the Authority. If the Shortlisted Applicants fails
                  to air content on his/her platforms then the Authority will
                  have right to terminate the contract with the Shortlisted
                  Applicant.
                </li>
                <li>
                  <ul>
                    <strong>Ownership of Content:</strong>
                    <li>
                      a) All content provided to the Shortlisted Applicant as
                      well as the content created and posted by the Shortlisted
                      Applicants under the Agreement will be owned by the
                      Authority, even if it is created by the Shortlisted
                      Applicants.
                    </li>
                    <li>
                      b) The content provided by the Authority to the
                      Shortlisted Applicant as well as the content created and
                      posted by the Shortlisted Applicant, in terms of this RFP,
                      shall not be used for any purpose by the Shortlisted
                      Applicant other than the intended purpose under this RFP
                      without the prior written permission of the Authority
                    </li>
                    <li>
                      c) The Authority reserves the right to use, modify, and
                      distribute the content as deemed necessary for
                      advertisement and promotional purposes.
                    </li>
                  </ul>
                </li>
                <li>
                  <ul>
                    <strong>Content Modification and Removal:</strong>
                    <li>
                      d. The Shortlisted Applicant will be required to promptly
                      remove or modify any content as informed by the Authority
                      if there are any errors, inaccuracies, noticed in the
                      contents or any changes are required to be carried out in
                      the content.
                    </li>
                    <li>
                      e. In the event of modification or variation of the
                      content and uploading of the modified and/or varied
                      content on the social media platform of the Shortlisted
                      Applicant, the payment will only be made for one post,
                      irrespective of the number of posts that need to be
                      replaced as a result of such modification and/or
                      variation.
                    </li>
                    <li>
                      f. Incase a post of the Shortlisted Applicant is deleted
                      or removed from their social media platforms as per the
                      request of the Authority, no payment will be made for that
                      particular post.
                    </li>
                  </ul>
                </li>

                <li>
                  The Shortlisted Applicant shall not modify the content
                  released by the Authority or use the content to create other
                  content with contradictory views or create a parody of the
                  content. Such an act will entitle the Authority to terminate
                  the Agreement.
                </li>

                <li>
                  <ul>
                    <strong>Duration and Evaluation:</strong>
                    <li>
                      a. The contract will be initially for a period of one year
                      and on satisfactory performance the term may be extended
                      further for a period of Two years at the sole discretion
                      of the Authority and as per the evaluation process defined
                      by the Authority.
                    </li>
                    <li>
                      b. The performance and effectiveness of the Shortlisted
                      Applicant will be evaluated every six months based on the
                      reach, engagement, and impact of their promotional
                      activities.
                    </li>
                    <li>
                      c. Categories (i.e. A, B, C and D) once decided will not
                      be changed for a period of 1 year.
                    </li>
                    <li>
                      d. Categories can be changed at the end of the year during
                      the renewal process.
                    </li>
                  </ul>
                </li>

                <li>
                  <strong>Submission of Proof of Post:</strong>
                  The shortlisted Applicant will be required to submit proof of
                  posting the content on their social media platform to the
                  Authority in the format as prescribed.
                </li>
              </ol>

              <Typography variant="h6" gutterBottom>
                PAYMENT/ REMUNERATION
              </Typography>

              <ol>
                Remuneration to all the Shortlisted Applicants who have entered
                into a contract with the Authority will be paid based on their
                respective categories and level of engagement as detailed below:
                <ul>
                  <li>
                    A. Remuneration to Shortlisted Applicant for posting of
                    Graphics provided by the Authority:
                  </li>
                  <TableContainer
                    sx={{ maxWidth: 400, border: `1px solid #000` }}
                  >
                    <Table aria-label="simple table">
                      <TableRow>
                        <TableCell>Category A</TableCell>
                        <TableCell> Rs. 2,000</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Category B</TableCell>
                        <TableCell> Rs. 1,500</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Category C</TableCell>
                        <TableCell> Rs. 1,000</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Category D</TableCell>
                        <TableCell> Rs. 500</TableCell>
                      </TableRow>
                    </Table>
                  </TableContainer>

                  <li>
                    B. Remuneration to Shortlisted Applicant for posting of
                    Audio Visuals provided by the Authority:
                  </li>
                  <TableContainer
                    sx={{ maxWidth: 400, border: `1px solid #000` }}
                  >
                    <Table aria-label="simple table">
                      <TableRow>
                        <TableCell>Category A</TableCell>
                        <TableCell> Rs. 4,000</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Category B</TableCell>
                        <TableCell> Rs. 3,000</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Category C</TableCell>
                        <TableCell> Rs. 2,000</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Category D</TableCell>
                        <TableCell> Rs. 1,500</TableCell>
                      </TableRow>
                    </Table>
                  </TableContainer>

                  <li>
                    C. Remuneration to Shortlisted Applicant for posting of
                    Audio Visuals created by the Shortlisted Applicant and
                    approved by the Authority:
                  </li>
                  <TableContainer
                    sx={{ maxWidth: 400, border: `1px solid #000` }}
                  >
                    <Table aria-label="simple table">
                      <TableRow>
                        <TableCell>Category A</TableCell>
                        <TableCell> Rs. 50,000</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Category B</TableCell>
                        <TableCell> Rs. 30,000</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Category C</TableCell>
                        <TableCell> Rs. 20,000</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Category D</TableCell>
                        <TableCell> Rs. 10,000</TableCell>
                      </TableRow>
                    </Table>
                  </TableContainer>
                </ul>
              </ol>

              <Typography variant="h6" gutterBottom>
                LEGAL INSTRUCTIONS TO THE APPLICANT:
              </Typography>

              <ol>
                <li>
                  <strong> Termination of Contract: </strong>Failure of the
                  Shortlisted Applicant to comply with the requirement of any
                  clause herein contained shall constitute sufficient ground for
                  the termination of the Contract.
                </li>

                <li>
                  <strong> Changes in the Contract </strong>
                  The Authority shall at any time, by written order given to the
                  Shortlisted Applicant, make changes within the general scope
                  of the contract and the Shortlisted Applicant should abide by
                  the same.
                </li>

                <li>
                  <strong>Law Governing Contract: </strong>Its meaning and
                  interpretation, and the relation between the Authority and the
                  Shortlisted Applicant shall be governed by the applicable laws
                  of India.
                </li>
              </ol>
            </Typography>
          </Grid>
          <Grid container justifyContent={"space-between"}>
            <Grid lg={10} md={12} xs={11}>
              <Grid container alignItems={"center"}>
                <Stack direction={"row"}>
                  <Checkbox
                    sx={{ mt: { md: "-10px" } }}
                    onChange={(e) => {
                      formik.values.check = e.target.checked;
                      if (e.target.checked)
                        formik.setFieldError("check", undefined);
                    }}
                  ></Checkbox>
                  <Typography variant="h6">
                    I confirm that I have read and accept the terms and
                    conditions.
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
            <Grid lg={2} md={12} xs={12} sx={{ mt: { xs: 2, lg: 0 } }}>
              <Button loading={loading} variant="contained" type="submit">
                Submit
              </Button>
            </Grid>
          </Grid>

          {formik?.errors?.check && formik.touched.check && (
            <FormHelperText sx={{ color: "#F04438", marginLeft: "14px" }}>
              {formik?.errors?.check}
            </FormHelperText>
          )}
        </form>
      </Grid>
    </Grid>
  );
}
const mapStateToProps = (state) => ({
  registerData: state.auth.registerData,
});

const mapDispatchToProps = (dispatch) => ({
  updateUserdetails: (data, step, callback) =>
    dispatch(updateUser(data, step, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(StepSix);
