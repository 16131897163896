import React, { useEffect, useState } from "react";
import { Layout } from "common/dashboard/layout";
import {
  Container,
  Grid,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Box,
  Chip,
} from "@mui/material";
import SelectSearch from "common/select";
import moment from "moment";
import { SearchInput } from "common/searchInput";
import { LoadingButton } from "@mui/lab";
import { connect } from "react-redux";
import { getAccounts } from "store/task/taskactions";
import { EmptyRecordContent } from "common/emptyRecordContent";
import { EmptyRecordPayment } from "common/EmptyRecordPayment";
import { textAlign } from "@mui/system";
import ConfirmPop from "common/modal/ConfirmPop";

function Archive({ getAccounts, accountlist }) {
  const [open, setOpen] = useState();
  const [view, setView] = useState();

  const [data, setData] = useState({});
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [keyword, setKeyword] = useState("");

  const statusMap = {
    pending: "#3211FF",
    approved: "#00B087",
    rejected: "#DF0404",
    needMoreDetail: "#FD884B",
  };
  const statusMapBC = {
    pending: "#C5E8FC",
    approved: "#A6E7D8",
    rejected: "#FFC5C5",
    needMoreDetail: "#FED2BB",
  };
  const [params, setParams] = useState({
    sort: "createdAt",
    order: "desc",
  });
  useEffect(() => {
    getData();
  }, [params]);
  useEffect(() => {
    const debounce = setTimeout(() => {
      if (keyword !== undefined) {
        getData();
      }
    }, 1000);
    return () => {
      clearTimeout(debounce);
    };
  }, [keyword]);

  const getData = () => {
    const payLoad = {
      ...params,
      //  page,
      keyword,
    };
    getAccounts(payLoad);
  };

  let options = [
    {
      name: "Sort by: Newest",
      value: "desc",
    },
    {
      name: "Sort by: Oldest",
      value: "asc",
    },
  ];
  let colorGrey = { color: "#B5B7C0", textAlign: "center" };
  let blackBold = { color: "#292D32", fontWeight: "600", textAlign: "center" };
  return (
    <div className="bg_wrapper">
      <Layout>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 5,
            px: 2,
            backgroundColor: "#e6e7e9",
            minHeight: "100vh",
          }}
        >
          <Container maxWidth="xl">
            <Grid container alignItems={"center"}>
              <Grid xs={12} sm={6} md={3} lg={5}>
                <SearchInput
                  placeholder="Search "
                  keyword={keyword}
                  setKeyword={setKeyword}
                />
              </Grid>
              <Grid xs={12} sm={6} md={3} lg={3}>
                <Box sx={{ mt: 2 }}>
                  <SelectSearch
                    value={options?.find((i) => i.value == params?.order)}
                    options={options}
                    setValue={(e, v) => {
                      setParams({
                        ...params,
                        sort: "createdAt",
                        order: v.value,
                      });
                    }}
                    keyword={keyword}
                    setKeyword={setKeyword}
                  />
                </Box>
              </Grid>
              <Grid
                item
                lg={4}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              ></Grid>
            </Grid>
            <Box
              sx={{
                minWidth: 800,
                px: 4,
                height: 640,
                backgroundColor: "#fff",
                borderRadius: "10px",
              }}
            >
              {accountlist?.data?.length != 0 ? (
                <Table>
                  <TableRow>
                    <TableCell sx={colorGrey}>Task</TableCell>
                    <TableCell sx={colorGrey}>Date</TableCell>
                    <TableCell sx={colorGrey}>Amount</TableCell>

                    <TableCell sx={colorGrey}>Payment Status</TableCell>
                    <TableCell sx={colorGrey}>Actions</TableCell>
                  </TableRow>
                  <TableBody>
                    {accountlist?.data?.map((row) => {
                      const createdAt = moment(row?.createdAt).format(
                        "DD/MM/YY"
                      );

                      return (
                        <TableRow hover key={row.id} onClick={() => {}}>
                          <TableCell sx={blackBold}>
                            {row?.task?.title}
                          </TableCell>
                          <TableCell sx={blackBold}>{createdAt}</TableCell>
                          <TableCell sx={blackBold}>{row?.amount}</TableCell>
                          <TableCell sx={blackBold}>
                            {row?.status == "invoiceRaised"
                              ? "Invoice Raised"
                              : row?.status}
                          </TableCell>

                          {row?.status == "pending" && (
                            <TableCell sx={blackBold}>
                              <LoadingButton
                                // sx={{ width: 120 }}
                                //  loading={loading}
                                fullWidth
                                variant={"contained"}
                                onClick={() => {
                                  setData(row);
                                  setOpen(true);
                                  setView(false);
                                }}
                              >
                                Raise Invoice
                              </LoadingButton>
                            </TableCell>
                          )}
                          {row?.status == "rejected" && (
                            <TableCell sx={[blackBold, { width: 250 }]}>
                              <p>{row?.invoice?.reason}</p>
                            </TableCell>
                          )}
                          {row?.status == "paid" && (
                            <TableCell sx={blackBold}>
                              <LoadingButton
                                // sx={{ width: 120 }}
                                //  loading={loading}
                                fullWidth
                                variant={"contained"}
                                onClick={() => {
                                  setData(row);
                                  setOpen(true);
                                  setView(true);
                                  // window.open(
                                  //   `${process.env.REACT_APP_IMG}${row?.invoice?.receipts?.[0]?.original}`
                                  // );
                                }}
                              >
                                View receipt
                              </LoadingButton>
                            </TableCell>
                          )}
                          {/* <TableCell sx={blackBold}>
                          <Chip
                            label={stat}
                            size={"medium"}
                            className="capitalize"
                            sx={{
                              width: "100%",
                              color: statusMap[stat],
                              backgroundColor: statusMapBC[stat],
                              borderRadius: "5px",
                              borderColor: statusMap[stat],
                              borderWidth: "1px",
                              borderStyle: "solid",
                              py: 2,
                            }}
                          />
                        </TableCell> */}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              ) : (
                <EmptyRecordPayment />
              )}
            </Box>
            <ConfirmPop
              open={open}
              setOpen={setOpen}
              amount={data.amount}
              data={data}
              invoiceId={data.invoice?.invoiceId}
              assignedTask={data.assignedTask}
              getData={getData}
              view={view}
            />
          </Container>
        </Box>
      </Layout>
    </div>
  );
}

const mapStateToProps = (state) => ({
  accountlist: state.task.accountlist,
});

const mapDispatchToProps = (dispatch) => ({
  getAccounts: (data, callback) => dispatch(getAccounts(data, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Archive);
