import React from "react";
import { Box, TextField, Typography } from "@mui/material";
import moment from "moment";

const TextInput = ({
  icon,
  type,
  placeholder,
  label,
  name,
  maxLength,
  isInvalid,
  bottomSpace,
  onBlur,
  onChange,
  value,
  error,
  readOnly,
  iconClick,
  disabled,
  handleBlur,
  style,
  multiline,
  rows,
}) => {
  return (
    <Box sx={{ mb: bottomSpace ? 0 : 3 }}>
      <Typography variant="label">{label}</Typography>
      <TextField
        fullWidth
        placeholder={placeholder}
        name={name}
        disabled={disabled}
        type={type}
        multiline={multiline}
        rows={rows}
        sx={{
          "& legend": { display: "none" },
          "& fieldset": { top: 0 },
          mt: 1,
        }}
        onWheel={(event) => event.target.blur()}
        inputProps={{
          //          min: moment().subtract(18, "years").format("yyyy-MM-DD"),

          style: {
            padding: "6px",
            paddingLeft: "15px",
            paddingRight: "15px",

            fontSize: "16px",
            height: multiline ? "auto" : "30px",
          },
          sx: {
            "&::placeholder": {
              color: "#757D8A",
              opacity: 1, // otherwise firefox shows a lighter color
            },
          },
        }}
        style={style}
        error={error}
        helperText={error}
        onChange={onChange}
        value={value}
        readOnly={readOnly}
      />
    </Box>
  );
};

export default TextInput;
