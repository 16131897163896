import React, { useEffect, useState } from "react";
import Header from "common/header/header";
import Footer from "common/footer";
import Background from "common/Background";
import {
  Grid,
  Typography,
  FormControl,
  FormHelperText,
  Button,
  Stack,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import {
  loginSchema,
  registerValidationSchema,
  resetSchema,
} from "utils/schema";
import { useNavigate } from "react-router-dom";
import TextInput from "common/TextInput";
import { forgotPasswordCall, logIn } from "store/auth/authActions";
import { connect } from "react-redux";

function ForgotPassword({ forgotPasswordC }) {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: resetSchema,
    onSubmit: async (values, helpers) => {
      try {
        setLoading(true);
        setError("");
        forgotPasswordC(values, async (res) => {
          setLoading(false);

          if (!res?.err) {
            alert(res?.data?.data);
            navigate("/login");
          }
          if (res?.err) {
            setError(res?.message);
          }
        });
      } catch (err) {
        setLoading(false);
      }
    },
  });

  return (
    <div className="bg_wrapper">
      <Background>
        <div className="white-box">
          <Grid
            container
            minHeight={"650px"}
            sx={{
              pt: { xs: "10px", md: "25px", lg: "80px" },
              pb: { xs: "10px", md: "50px", lg: "50px" },
              pr: { xs: "10px", md: "55px", lg: "100px" },
              pl: { xs: "10px", md: "40px", lg: "40px" },
              backgroundImage: `url('img/back4.svg')`,
              backgroundPosition: `center center`,
              backgroundSize: "contain",
              borderRadius: `21.529px`,
              position: "relative",
            }}
          >
            <Grid
              lg={6}
              md={6}
              alignItems={"flex-start"}
              sx={{ margin: { xs: "0 auto" } }}
            >
              <img src="img/left.png" className="register-box" />
            </Grid>
            <Grid
              lg={6}
              md={6}
              sx={{
                textAlign: "left",
                mt: { xs: 5, lg: 0 },
                pl: { md: 0, lg: 10 },
              }}
            >
              <Typography variant="title">Reset your password</Typography>
              <Grid sx={{ pl: { lg: 4, md: 4 } }}>
                <Typography variant="subtitle1" sx={{ mt: 5 }}></Typography>

                <Typography variant="subtitle3" sx={{ mb: 5 }}>
                  Enter your email which you had use while Registering.
                </Typography>
                <form
                  noValidate
                  onSubmit={formik.handleSubmit}
                  style={{ marginTop: "25px" }}
                >
                  <TextInput
                    style={{ backgroundColor: "white" }}
                    type="text"
                    placeholder="Enter Your Email"
                    label={"Email"}
                    name="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    error={formik.touched.email ? formik.errors.email : ""}
                  />
                  {error != "" && (
                    <Typography className="text-danger">{error}</Typography>
                  )}
                  <LoadingButton
                    loading={loading}
                    fullWidth
                    size="large"
                    sx={{ mt: 10 }}
                    type="submit"
                    variant="contained"
                  >
                    Reset Password{" "}
                  </LoadingButton>{" "}
                </form>
                <br />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Background>
    </div>
  );
}

const mapStateToProps = (state) => ({
  registerData: state.auth.registerData,
});

const mapDispatchToProps = (dispatch) => ({
  forgotPasswordC: (data, callback) =>
    dispatch(forgotPasswordCall(data, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
