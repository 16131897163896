import { useCallback } from "react";
import PropTypes from "prop-types";
import { Divider, MenuItem, MenuList, Popover } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const AccountPopover = (props) => {
  const { anchorEl, onClose, open, logout } = props;
  const router = useNavigate();
  const user =
    typeof window !== "undefined"
      ? JSON.parse(localStorage.getItem("user"))
      : null;
  const handleSignOut = useCallback(() => {
    onClose?.();
    logout(() => router("/login"));
  }, [onClose, router, logout]);

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "left",
        vertical: "top",
      }}
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 250, marginTop: 5 } }}
    >
      {/* <Box
        sx={{
          py: 1.5,
          px: 2,
        }}
      >
        <Typography variant="overline">Account</Typography>
        <Typography color="text.secondary" variant="body2" sx={{ textTransform: "capitalize" }}>
          {user?.name}
        </Typography>
      </Box> */}
      <Divider />
      <MenuList
        disablePadding
        dense
        sx={{
          p: "8px",
          "& > *": {
            borderRadius: 1,
          },
        }}
      >
        <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
      </MenuList>
    </Popover>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  // user: PropTypes.object.isRequired, // Adjust the prop type for user
  // logout: PropTypes.func.isRequired, // Adjust the prop type for logout
};

// const mapStateToProps = (state) => ({
//   user: state.auth,
// });

// const mapDispatchToProps = (dispatch) => ({
//   logout: (callback) => dispatch(logout(callback)),
// });

export default AccountPopover;
