export const createTypography = () => {
  return {
    fontFamily: '"Inter"',
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: 1.57,
    },
    button: {
      fontWeight: 600,
    },
    caption: {
      fontSize: "0.75rem",
      fontWeight: 500,
      lineHeight: 1.66,
    },
    title: {
      color: "#000",
      fontSize: "25px",

      fontWeight: 700,
      [0 - 500]: {
        fontSize: "20px",
      },
    },
    subtitle1: {
      fontSize: "18px",
      fontWeight: 700,
      lineHeight: 1.57,
    },
    subtitle2: {
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: 1.57,
    },
    subtitle3: {
      fontSize: "18px",
      fontWeight: 500,
      lineHeight: 1.57,
    },
    overline: {
      fontSize: "0.75rem",
      fontWeight: 600,
      letterSpacing: "0.5px",
      lineHeight: 2.5,
      textTransform: "uppercase",
    },
    h1: {
      fontFamily: "'Plus Jakarta Sans', sans-serif",
      fontWeight: 700,
      fontSize: "3.5rem",
      lineHeight: 1.2,
    },
    h2: {
      fontFamily: "'Plus Jakarta Sans', sans-serif",
      fontWeight: 700,
      fontSize: "3rem",
      lineHeight: 1.2,
    },
    h3: {
      fontFamily: "'Plus Jakarta Sans', sans-serif",
      fontWeight: 700,
      fontSize: "2.25rem",
      lineHeight: 1.2,
    },
    h4: {
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: 1.2,
    },
    contentdesc: {
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 500,
      fontSize: "20px",
      lineHeight: 1.2,
      color: "#4A4A4A",
    },
    smallcontent: {
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 500,
      fontSize: "12px",
      color: "#000000",
    },
    h5: {
      fontFamily: "'Plus Jakarta Sans', sans-serif",
      fontWeight: 700,
      fontSize: "1.5rem",
      lineHeight: 1.2,
    },

    h7: {
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 400,
      fontSize: "11px",
      lineHeight: 1.2,
      color: "#101214",
    },

    boldroboto: {
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 800,
      fontSize: "12px",
      color: "#000000",
    },

    bhead: {
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 800,
      fontSize: "14px",
      color: "#000000",
    },
    h6: {
      fontFamily: "Inter",
      fontWeight: 700,
      fontSize: "1.125rem",
      lineHeight: 1.2,
    },
    label: {
      fontSize: "18px",
      fontWeight: 600,
      color: "#101214",
    },
    content: {
      color: "#6B6B6B",
      fontFamily: "Roboto Flex, sans-serif",
      fontSize: "17px",
      fontWeight: 500,
      lineHeight: "28px",
    },
    checkbox: {
      fontFamily: "Roboto",
      color: `rgba(17, 17, 17, 0.48)`,
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "16px",
    },
    cardHead: {
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 500,
      fontSize: "16px",
      color: "#000000",
    },
  };
};
