import api from "store/api/api";

//  Login
export const Add_Task = (data) => api.post("/userContent", data);
export const UPDATE_STATUS = (data, id) =>
  api.put(`/assignedTask/status/${id}`, data);

export const UploadeImages = (data) => api.post(`/task/uploadFiles/task`, data);
export const COUNT_HOME = () => api.get(`/user/count`);
export const GET_TASK = (params) => api.get(`/assignedTask`, { params });
export const GET_TASK_BYID = (id) => api.get(`/task/${id}`);
export const GET_CONTENT = (params) => api.get(`/userContent`, { params });
export const GET_ASSIGNEDTASK = (params) =>
  api.get(`/assignedTask`, { params });

export const Add_PS = (data) => api.post("/paymentStructure", data);
export const GET_PS = (params) => api.get(`/paymentStructure`, { params });

export const GET_ACCOUNT = (params) => api.get(`/account`, { params });
export const Add_Invoice = (data) => api.post("/invoice", data);
export const POST_Revise_Content = (data, id) =>
  api.put(`/userContent/${id}`, data);
