import React, { useEffect, useState } from "react";
import { Layout } from "common/dashboard/layout";
import {
  Stack,
  Grid,
  Typography,
  Box,
  Container,
  TextField,
  Chip,
  Divider,
} from "@mui/material";
import { NoTaskCard } from "common/NoTaskCard";
import { getContent } from "store/task/taskactions";
import { connect } from "react-redux";
import { EmptyRecordContent } from "common/emptyRecordContent";
import { LoadingButton } from "@mui/lab";
import TextInput from "common/TextInput";
import SelectSearch from "common/select";
import CreateContent from "common/modal/CreateContent";
import moment from "moment";
import { SearchInput } from "common/searchInput";
import ReviseContent from "common/modal/ReviseContent";

function OwnContent({ getowncontent, content }) {
  const [open, setOpen] = useState();
  const [open2, setOpen2] = useState();
  const [taskData, setTaskData] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [keyword, setKeyword] = useState("");

  const statusMap = {
    pending: "#3211FF",
    approved: "#00B087",
    rejected: "#DF0404",
    needMoreDetail: "#FD884B",
  };
  const statusMapBC = {
    pending: "#C5E8FC",
    approved: "#A6E7D8",
    rejected: "#FFC5C5",
    needMoreDetail: "#FED2BB",
  };
  const [params, setParams] = useState({
    sort: "createdAt",
    order: "desc",
  });
  useEffect(() => {
    getData();
  }, [params]);
  useEffect(() => {
    const debounce = setTimeout(() => {
      if (keyword !== undefined) {
        getData();
      }
    }, 1000);
    return () => {
      clearTimeout(debounce);
    };
  }, [keyword]);

  const getData = () => {
    const payLoad = {
      ...params,
      //  page,
      keyword,
    };
    getowncontent(payLoad);
  };

  let options = [
    {
      name: "Sort by: Newest",
      value: "desc",
    },
    {
      name: "Sort by: Oldest",
      value: "asc",
    },
  ];

  return (
    <div>
      <Layout>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 5,
            px: 2,
            backgroundColor: "#e6e7e9",
            minHeight: "100vh",
          }}
        >
          <Container maxWidth="xl">
            <Grid container alignItems={"center"}>
              <Grid xs={12} sm={6} md={3} lg={5}>
                <SearchInput
                  placeholder="Search "
                  keyword={keyword}
                  setKeyword={setKeyword}
                />
              </Grid>
              <Grid xs={12} sm={6} md={3} lg={3}>
                <Box sx={{ mt: 2 }}>
                  <SelectSearch
                    value={options?.find((i) => i.value == params?.order)}
                    options={options}
                    setValue={(e, v) => {
                      setParams({
                        ...params,
                        sort: "createdAt",
                        order: v.value,
                      });
                    }}
                    keyword={keyword}
                    setKeyword={setKeyword}
                  />
                </Box>
              </Grid>
              <Grid
                item
                lg={4}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <LoadingButton
                  variant="contained"
                  onClick={() => setOpen(true)}
                >
                  Add Own Content
                </LoadingButton>
              </Grid>
            </Grid>

            <Stack sx={{ mb: "1.5rem" }} mt={3}>
              {content?.data?.length != 0 ? (
                <Box>
                  {content?.data?.map((i) => (
                    <Grid
                      container
                      sx={{ backgroundColor: "#fff" }}
                      p={4}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Grid
                        item
                        justifyContent={"center"}
                        alignItems={"center"}
                        lg={2}
                        sm={12}
                      >
                        <Stack direction={"row"}>
                          {i?.media?.[0]?.original && (
                            <video
                              width="260"
                              height="250"
                              controls
                              key={i._id}
                              preload="none"
                            >
                              <source
                                src={`${process.env.REACT_APP_IMG}${i?.media?.[0]?.original}`}
                                type="video/mp4"
                              />
                            </video>
                          )}
                        </Stack>
                      </Grid>
                      <Grid lg={6}>
                        <Stack ml={3} spacing={2} mt={1} width={"100%"}>
                          <div>
                            <Typography
                              variant="cardHead"
                              fontWeight={"bold"}
                              gutterBottom
                              textTransform={"capitalize"}
                            >
                              {i?.title}
                            </Typography>
                            <br />
                            <Typography variant="labelForm" gutterBottom>
                              {i?.description}
                            </Typography>
                            <br></br>
                            {i?.comment && (
                              <Typography variant={"caption"} gutterBottom>
                                <strong>Comment for revision:</strong>{" "}
                                {i?.comment}
                              </Typography>
                            )}
                            <br></br>
                            {i?.media?.[0]?.original && (
                              <a
                                href={`${process.env.REACT_APP_IMG}${i?.media?.[0]?.original}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Download Video
                              </a>
                            )}
                          </div>
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                          >
                            <Typography variant="h7">
                              Date: {moment(i?.createdAt).format("DD/MM/YYYY")}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Grid>

                      <Box sx={{ minWidth: 150 }}>
                        {
                          <Chip
                            label={i?.approvalStatus}
                            size={"medium"}
                            onClick={() => {
                              if (i?.approvalStatus == "revise") {
                                setTaskData(i);
                                setOpen2(true);
                              }
                            }}
                            className="capitalize"
                            sx={{
                              cursor:
                                i?.approvalStatus == "revise"
                                  ? "pointer"
                                  : "default",
                              width: "100%",
                              color: statusMap[i?.approvalStatus],
                              backgroundColor: statusMapBC[i?.approvalStatus],
                              borderRadius: "5px",
                              borderStyle: "solid",
                              py: 2,
                            }}
                          />
                        }
                      </Box>
                    </Grid>
                  ))}
                </Box>
              ) : (
                <EmptyRecordContent />
              )}
            </Stack>
            {open && (
              <CreateContent open={open} setOpen={setOpen} getData={getData} />
            )}
            {open2 && (
              <ReviseContent
                open={open2}
                taskData={taskData}
                setOpen={setOpen2}
                getData={getData}
              />
            )}
          </Container>
        </Box>
      </Layout>
    </div>
  );
}

const mapStateToProps = (state) => ({
  content: state.task.content,
});

const mapDispatchToProps = (dispatch) => ({
  getowncontent: (data, callback) => dispatch(getContent(data, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(OwnContent);
