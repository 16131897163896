// actions.js
import {
  authTriggered,
  authSuccess,
  authFailure,
  logoutUser,
  getUserSuccess,
  registerDataSuccess,
  registerSuccess,
  updateStepCompleted,
  categorySuccess,
  getCountSuccess,
  notificationListSuccess,
  notificationSuccess,
} from "./authSlices";

import {
  LOGIN,
  REGISTER,
  GETUSERDETAILS,
  UPDATE_USER,
  VERIFY_PHONE_NUMBER,
  UPLOADFILE,
  GETCATE,
  GETMYDETAILS,
  GETMYCOUNT,
  forgotPassword,
  verifyResetPasswordCode,
  resetPassword,
  UPDATE_Payment_Detail,
  UPDATE_NOTI,
  GET_NOTI_LIST,
  GET_NOTI_COUNT,
} from "./authAPI";
//  Login
export const logIn = (credentials, callback) => async (dispatch) => {
  try {
    dispatch(authTriggered());
    const response = await LOGIN(credentials);
    if (response.status === 200) {
      if (response.data.data.status == "approved") {
        dispatch(authSuccess(response.data.data));
        localStorage.setItem("auth", true);

        localStorage.setItem("user-auth-x-token", response.data.data.jwtToken);
        localStorage.setItem("user", JSON.stringify(response.data.data));
        const response2 = await GETMYDETAILS();
        dispatch(getUserSuccess(response2?.data?.data));

        const response3 = await GETMYCOUNT();
        dispatch(getCountSuccess(response3?.data?.data));
      }
      if (response.data.data.status == "needMoreDetail") {
        localStorage.setItem("user-auth-x-token", response.data.data.jwtToken);
        localStorage.setItem("user", JSON.stringify(response.data.data));
        const response2 = await GETMYDETAILS();
        dispatch(getUserSuccess(response2?.data?.data));
      }

      if (response.data.data.status == "rejected") {
        localStorage.setItem("user-auth-x-token", response.data.data.jwtToken);

        const response2 = await GETMYDETAILS();
        localStorage.removeItem("user-auth-x-token");

        dispatch(getUserSuccess(response2?.data?.data));
      }

      callback(response.data);
    } else {
      dispatch(authFailure(response.data));
      callback(response?.response?.data);
    }
  } catch (error) {
    console.log("ERRO", error);
    callback(error.response?.data);
    dispatch(authFailure(error.response?.data));
  }
};

export const registerUser =
  (credentials, step, callback) => async (dispatch) => {
    try {
      dispatch(authTriggered());
      const response = await REGISTER(credentials);
      if (response.status === 200) {
        dispatch(registerSuccess(response.data.data));
        dispatch(updateStepCompleted(step));
        localStorage.setItem("user-auth-x-token", response.data.data.jwtToken);
        callback({ err: false, data: response.data });
      } else {
        dispatch(authFailure(response.data));
        callback({ err: true, msg: response?.response?.data?.message });
      }
    } catch (error) {
      console.log(error.response?.data?.message);
      callback({ err: true, msg: error.response?.data?.message });
      dispatch(authFailure(error.response?.data));
    }
  };

export const saveLoginData = (payLoad) => async (dispatch) => {
  dispatch(authSuccess(payLoad));
};

export const saveRegisterData = (payLoad, step) => async (dispatch) => {
  dispatch(updateStepCompleted(step));

  dispatch(registerDataSuccess(payLoad));
};

export const getDetails = (credentials, callback) => async (dispatch) => {
  try {
    const response = await GETMYDETAILS();
    if (response.status === 200) {
      dispatch(getUserSuccess(response.data.data));
      callback(response.data);
    } else if (response.ok === false) {
      callback(response.data);
    }
  } catch (error) {
    callback(error.response);
  }
};

export const getmyCount = (credentials) => async (dispatch) => {
  try {
    const response = await GETMYCOUNT();
    if (response.status === 200) {
      dispatch(getCountSuccess(response?.data?.data));
    } else if (response.ok === false) {
    }
  } catch (error) {}
};

export const getCategory = (credentials) => async (dispatch) => {
  try {
    const response = await GETCATE();
    if (response.status === 200) {
      dispatch(categorySuccess(response.data.data));
    } else if (response.ok === false) {
    }
  } catch (error) {}
};

export const updateUser = (data, step, callback) => async (dispatch) => {
  try {
    const response = await UPDATE_USER(data);
    if (response.status === 200) {
      dispatch(registerDataSuccess(data));

      dispatch(updateStepCompleted(step));

      callback({ err: false, data: response.data });
    } else {
      callback({ err: true, msg: response?.response?.data?.message });
    }
  } catch (error) {
    callback({ err: true, msg: error.response?.data?.message });
  }
};
//  Logout
export const logout = (callback) => async (dispatch) => {
  dispatch(logoutUser());
  localStorage.removeItem("user-auth-x-token");
  localStorage.removeItem("user");
  localStorage.clear();

  callback();
};

export const verifyPhoneNumber = (data, callback) => async (dispatch) => {
  try {
    const response = await VERIFY_PHONE_NUMBER(data);
    if (response.status === 200) {
      callback(response.data);
    } else {
      callback(response.response?.data);
    }
  } catch (error) {}
};

export const updateDocuments = (data, callback) => async (dispatch) => {
  try {
    let formdata1 = new FormData();
    let formdata2 = new FormData();
    let formdata3 = new FormData();
    formdata1.append("file", data.govtIdProof);
    formdata2.append("file", data.birthCertProof);
    formdata3.append("file", data.residenceCertProof);

    const response1 = await UPLOADFILE("govtIdProof", formdata1);
    const response2 = await UPLOADFILE("birthCertProof", formdata2);
    const response = await UPLOADFILE("residenceCertProof", formdata3);

    if (response.status === 200) {
      dispatch(updateStepCompleted(3));
      callback({ err: false, data: response.data });
    } else {
      callback({ err: true, msg: response?.response?.data?.message });
    }
  } catch (error) {
    callback({ err: true, msg: error.response?.data?.message });
  }
};

export const forgotPasswordCall =
  (credentials, callback) => async (dispatch) => {
    try {
      const response = await forgotPassword(credentials);
      if (response.status === 200) {
        callback({ err: false, data: response.data });
      } else {
        dispatch(authFailure(response.data));
        callback({ err: true, msg: response?.response?.data?.message });
      }
    } catch (error) {
      console.log(error.response?.data?.message);
      callback({ err: true, msg: error.response?.data?.message });
    }
  };

export const setPasswordCall = (credentials, callback) => async (dispatch) => {
  try {
    const response = await verifyResetPasswordCode({
      email: credentials?.email,
      code: credentials?.code,
    });
    if (response.status === 200) {
      const response2 = await resetPassword(
        { newPassword: credentials?.password },
        response?.data?.message?.id
      );
      if (response2.status === 200) {
        callback({ err: false, data: response2.data });
      } else {
        callback({ err: true, msg: response2?.response?.data?.message });
      }
    } else {
      callback({ err: true, msg: response?.response?.data?.message });
    }
  } catch (error) {
    console.log(error.response?.data?.message);
    callback({ err: true, msg: error.response?.data?.message });
  }
};

export const updatePaymentDetails =
  (credentials, callback) => async (dispatch) => {
    try {
      const response = await UPDATE_Payment_Detail(credentials);
      if (response.status === 200) {
        const response2 = await GETMYDETAILS();
        if (response2.status === 200) {
          dispatch(getUserSuccess(response2.data.data));
          callback({ err: false, data: response.data });
        }
      } else {
        callback({ err: true, msg: response?.response?.data?.message });
      }
    } catch (error) {
      console.log(error.response?.data?.message);
      callback({ err: true, msg: error.response?.data?.message });
    }
  };

export const getNotiCOunt = (params) => async (dispatch) => {
  try {
    const response = await GET_NOTI_COUNT(params);
    if (response.status === 200) {
      dispatch(notificationSuccess({ data: response.data.data }));
    } else if (response.ok === false) {
    }
  } catch (error) {
    console.log(error);
  }
};

export const getNotificaitons = (params) => async (dispatch) => {
  try {
    const response = await GET_NOTI_LIST(params);
    if (response.status === 200) {
      dispatch(notificationListSuccess({ data: response.data.data }));
    } else if (response.ok === false) {
    }
  } catch (error) {
    console.log(error);
  }
};

export const makAsRead = (params, data) => async (dispatch) => {
  try {
    const postRes = await UPDATE_NOTI(data);
    const response = await GET_NOTI_LIST(params);
    if (response.status === 200) {
      dispatch(notificationListSuccess({ data: response.data.data }));
    }

    const response2 = await GET_NOTI_COUNT(params);
    if (response2.status === 200) {
      dispatch(notificationSuccess({ data: response2.data.data }));
    }
  } catch (error) {
    console.log(error);
  }
};
