import { Box, Button, Container, Stack, Typography } from "@mui/material";

export const NoTaskCard = ({ title, setopen, setselectedCategory, id }) => {
  return (
    <Container
      sx={{ padding: 3, backgroundColor: "#EEF2F5", borderRadius: "10px" }}
    >
      <Typography variant="cardHead">{title}</Typography>

      <Stack justifyContent={"center"} alignItems={"center"}>
        <img
          src="/img/notask.png"
          style={{ width: "100px", marginTop: "25px", marginBottom: "40px" }}
        />
        <Typography variant="h4" gutterBottom>
          No Task
        </Typography>
        <Typography variant="smallcontent">
          There is no task has been assigned yet.
        </Typography>
      </Stack>
    </Container>
  );
};
