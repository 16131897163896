import { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Divider,
  ListItem,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

export const NotificationPopover = (props) => {
  const {
    anchorEl,
    onClose,
    open,
    logout,
    getNotificaitons,
    Notifications,
    makAsRead,
  } = props;
  const user =
    typeof window !== "undefined"
      ? JSON.parse(localStorage.getItem("user"))
      : null;
  // const router = useNavigation();

  useEffect(() => {
    getNotificaitons({
      page: 1,
      isRead: false,
      sort: "createdAt",
      order: "desc",
    });
  }, []);
  const router = useNavigate();

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 300, marginTop: 5 } }}
    >
      <MenuList
        dense
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
      >
        {Notifications?.data?.map((i) => (
          <MenuItem
            key={i._id}
            onClick={() => {
              // if (i.type == "ownContentCreated") {
              //   router.push("/contents");
              // }
              // if (i.type == "taskSubmitted") {
              //   router.push("/task/influencertask");
              // }
              makAsRead(
                { page: 1, isRead: false },
                { notificationIds: [i?._id] }
              );
            }}
          >
            <div>
              <ListItemText
                primaryTypographyProps={{
                  style: {
                    whiteSpace: "normal",
                    textTransform: "capitalize",
                    paddingBottom: "5px",
                  },
                }}
              >
                {i?.sender?.firstName} {i?.sender?.lastName}{" "}
                {i.type == "taskAssigned"
                  ? "Assigned New Task "
                  : i.type == "applicationApproved"
                  ? "Your application is approved"
                  : ""}{" "}
                {i?.task?.title ? i?.task?.title : ""}
                {i.type == "taskCompleted" ? " is marked as complete" : ""}
              </ListItemText>
              <Divider style={{ borderColor: "#fd884b" }} />
            </div>
          </MenuItem>
        ))}
      </MenuList>
    </Popover>
  );
};

NotificationPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  // user: PropTypes.object.isRequired, // Adjust the prop type for user
  // logout: PropTypes.func.isRequired, // Adjust the prop type for logout
};

// const mapStateToProps = (state) => ({
//   user: state.auth,
// });

// const mapDispatchToProps = (dispatch) => ({
//   logout: (callback) => dispatch(logout(callback)),
// });

export default NotificationPopover;
