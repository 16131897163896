import * as yup from "yup";
import YupPassword from "yup-password";
YupPassword(yup);
//  Auth Schema

yup.addMethod(yup.mixed, "fileSize", function (maxSize, message) {
  return this.test("fileSize", message, (value) => {
    if (!value.length) return true; // If no files, return true (consider using .required() for file input validation)
    let valid = true;
    for (let i = 0; i < value.length; i++) {
      if (value[i].size > maxSize) {
        valid = false;
        break;
      }
    }
    return valid;
  });
});

// Custom Yup method to validate file type
yup.addMethod(yup.mixed, "fileType", function (types, message) {
  return this.test("fileType", message, (value) => {
    if (!value.length) return true; // If no files, return true
    let valid = true;
    for (let i = 0; i < value.length; i++) {
      if (!types.includes(value[i].type)) {
        valid = false;
        break;
      }
    }
    return valid;
  });
});

export const verifyNumberSchema = yup.object().shape({
  mobile: yup
    .string()
    .required("Mobile Number is Required")
    .min(10, "Mobile Number must be at least 10 characters")
    .matches(/^\+91\d{10}$/, "Mobile number is not valid"),
});
export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
  password: yup
    .string()
    .required("Password is Required")
    .min(
      8,
      "Password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special"
    )
    .minLowercase(1, "Password must contain at least 1 lower case letter")
    .minUppercase(1, "Password must contain at least 1 upper case letter")
    .minNumbers(1, "Password must contain at least 1 number")
    .minSymbols(1, "Password must contain at least 1 special character"),
});

export const passwordSchema = yup.object().shape({
  password: yup
    .string()
    .required("Password is Required")
    .min(
      8,
      "Password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special"
    )
    .minLowercase(1, "Password must contain at least 1 lower case letter")
    .minUppercase(1, "Password must contain at least 1 upper case letter")
    .minNumbers(1, "Password must contain at least 1 number")
    .minSymbols(1, "Password must contain at least 1 special character"),
});

export const resetSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
});

export const proofvalidationSchema = yup.object().shape({
  files: yup
    .mixed()
    .test("fileSize", "File size is too large", (value) => {
      // Assuming value is a File object from an <input type="file"> element
      return value ? value.size <= 250 * 1024 * 1024 : true; // Limit file size to 2MB
    })
    .required("Invoice file is required"),
});

//  Booking schema
export const createBookingSchema = yup.object().shape({
  name: yup.string().required("Customer Name is Required"),
  mobile: yup
    .string()
    .matches(
      /^\+[0-9]{1,4}[0-9]{6,14}$/,
      "Invalid mobile number with country code"
    )
    .required("Mobile number is required"),
  date: yup.date().required("Date is required"),
  seatsRequired: yup.object().shape({
    name: yup.string().nullable(),
    value: yup.string().required("Seats is Required"),
    data: yup.string().nullable(),
  }),
});
export const linkvalidationSchema = yup.object({
  links: yup
    .array()
    .of(yup.string().url("One of link is invalid URL"))
    .test("is-unique", "All links must be unique", function (value) {
      const linksSet = new Set(value);
      return linksSet.size === value.length;
    })
    .min(60, "60 valid links is required"),
});
export const registerValidationSchema = yup.object().shape({
  firstname: yup.string().required("First name is required"),
  lastname: yup.string().required("Last name is required"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
  dob: yup
    .date()
    .nullable()
    .required("Date of birth is required")
    .max(new Date(), "Date of birth must be in the past"),

  address1: yup.string().required("Address 1 is required"),
  address2: yup.string(),
  city: yup.string().required("City is required"),
  zip: yup.string().required("ZIP code is required"),
  mobile: yup
    .string()
    .required("Mobile Number is Required")
    .min(15, "Mobile Number must be at least 10 characters"),
  password: yup
    .string()
    .required("Password is Required")
    .min(
      8,
      "Password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special"
    )
    .minLowercase(1, "Password must contain at least 1 lower case letter")
    .minUppercase(1, "Password must contain at least 1 upper case letter")
    .minNumbers(1, "Password must contain at least 1 number")
    .minSymbols(1, "Password must contain at least 1 special character"),

  is18: yup.boolean().test({
    name: "is-true",
    test: (value) => value === true,
    message: "You must confirm that you are 18 or older",
  }),
  is15: yup.boolean().test({
    name: "is-true",
    test: (value) => value === true,
    message:
      "You must confirm that you have been a resident of Goa for 15 years or more.",
  }),
  sixty: yup.boolean().test({
    name: "is-true",
    test: (value) => value === true,
    message:
      "You must confirm that you have uploaded 60 or more posts in the last 6 months.",
  }),
});

export const contentValidationSchema = yup.object().shape({
  description: yup.string().required("Description is required"),
  title: yup.string().required("Title is required"),

  mediafile: yup
    .mixed()
    .test("fileSize", "File size is too large", (value) => {
      // Assuming value is a File object from an <input type="file"> element
      return value ? value.size <= 250 * 1024 * 1024 : true; // Limit file size to 2MB
    })
    .required("Video file is required"),
});

export const revisioncontentValidationSchema = yup.object().shape({
  description: yup.string().required("Description is required"),
  title: yup.string().required("Title is required"),
});
export const submitproofValidationSchema = yup.object().shape({
  url: yup.string().required("Url is required"),

  proof: yup
    .mixed()
    .test("fileSize", "File size is too large", (value) => {
      // Assuming value is a File object from an <input type="file"> element
      return value ? value.size <= 250 * 1024 * 1024 : true; // Limit file size to 2MB
    })
    .required("Proof file is required"),
});

export const documentValidationSchema = yup.object().shape({
  govtIdProof: yup.mixed().required("Gov. Id Proof file is required"),
  birthCertProof: yup.mixed().required("Birth Certificate is required"),
  residenceCertProof: yup.mixed().required("Resident Certificate is required"),
  check: yup.boolean().test({
    name: "is-true",
    test: (value) => value === true,
    message:
      "You must declare that the information/documents are true to the best of your knowledge.",
  }),
});

export const socialvalidationSchema = yup.object().shape({
  primaryAcc: yup.string().required("Primary Account is required"),
  primaryFollow: yup
    .number() // Ensure the value is a number
    .required("Select Primary Account ") // Error message if the field is empty
    .min(10000, "Followers must be 10,000 or more"),
  ytlink: yup
    .string()
    .url("Invalid YouTube link format")
    .test(
      "Instagram",
      "YouTube Link is required when selected primary account",
      function (value) {
        const fblink = this.parent.primaryAcc;
        return (
          !(fblink && fblink.trim() == "youtube") ||
          (fblink && fblink.trim() !== "" && value && value.trim() !== "")
        );
      }
    ),
  ytfollow: yup
    .string()
    .test(
      "requiredIfLinkProvided",
      "YouTube followers field is required when link is provided",
      function (value) {
        const fblink = this.parent.ytlink;
        return (
          !(fblink && fblink.trim() !== "") ||
          (fblink && fblink.trim() !== "" && value && value.trim() !== "")
        );
      }
    )
    .test(
      "test2",
      "YouTube followers field is required when selected primary account",
      function (value) {
        const fblink = this.parent.primaryAcc;
        return (
          !(fblink && fblink.trim() == "youtube") ||
          (fblink && fblink.trim() !== "" && value && value.trim() !== "")
        );
      }
    ),
  twlink: yup
    .string()
    .url("Invalid Twitter link format")
    .test(
      "Instagram",
      "Twitter Link is required when selected primary account",
      function (value) {
        const fblink = this.parent.primaryAcc;
        return (
          !(fblink && fblink.trim() == "twitter") ||
          (fblink && fblink.trim() !== "" && value && value.trim() !== "")
        );
      }
    ),
  twfollow: yup
    .string()
    .test(
      "requiredIfLinkProvided",
      "Twitter followers field is required when link is provided",
      function (value) {
        const fblink = this.parent.twlink;
        return (
          !(fblink && fblink.trim() !== "") ||
          (fblink && fblink.trim() !== "" && value && value.trim() !== "")
        );
      }
    )
    .test(
      "test2",
      "Twitter followers field is required when selected primary account",
      function (value) {
        const fblink = this.parent.primaryAcc;
        return (
          !(fblink && fblink.trim() == "twitter") ||
          (fblink && fblink.trim() !== "" && value && value.trim() !== "")
        );
      }
    ),
  fblink: yup
    .string()
    .url("Invalid Facebook link format")
    .test(
      "Instagram",
      "Facebook Link is required when selected primary account",
      function (value) {
        const fblink = this.parent.primaryAcc;
        return (
          !(fblink && fblink.trim() == "facebook") ||
          (fblink && fblink.trim() !== "" && value && value.trim() !== "")
        );
      }
    ),

  fbfollow: yup
    .string()
    .test(
      "requiredIfLinkProvided",
      "Facebook followers field is required when link is provided",
      function (value) {
        const fblink = this.parent.fblink;
        return (
          !(fblink && fblink.trim() !== "") ||
          (fblink && fblink.trim() !== "" && value && value.trim() !== "")
        );
      }
    )
    .test(
      "test2",
      "Facebook followers field is required when selected primary account",
      function (value) {
        const fblink = this.parent.primaryAcc;
        return (
          !(fblink && fblink.trim() == "facebook") ||
          (fblink && fblink.trim() !== "" && value && value.trim() !== "")
        );
      }
    ),
  instalink: yup
    .string()
    .url("Invalid Instagram link format")
    .test(
      "Instagram",
      "Instagram Link is required when selected primary account",
      function (value) {
        const fblink = this.parent.primaryAcc;
        return (
          !(fblink && fblink.trim() == "instagram") ||
          (fblink && fblink.trim() !== "" && value && value.trim() !== "")
        );
      }
    ),
  instafollow: yup
    .string()
    .test(
      "Instagram",
      "Instagram followers field is required when selected primary account",
      function (value) {
        const fblink = this.parent.primaryAcc;
        return (
          !(fblink && fblink.trim() == "instagram") ||
          (fblink && fblink.trim() !== "" && value && value.trim() !== "")
        );
      }
    )
    .test(
      "requiredIfLinkProvided",
      "Instagram followers field is required when link is provided",
      function (value) {
        const fblink = this.parent.instalink;
        return (
          !(fblink && fblink.trim() !== "") ||
          (fblink && fblink.trim() !== "" && value && value.trim() !== "")
        );
      }
    ),
  file: yup.mixed().required("Screenshot file is required"),
});

export const paymentSchema = yup.object().shape({
  accountHolderName: yup
    .string()
    .required("Account holder name is required")
    .min(3, "Name must be at least 3 characters long"),
  bankName: yup
    .string()
    .required("Bank name is required")
    .min(3, "Bank name must be at least 3 characters long"),
  accountNumber: yup
    .string()
    .required("Account number is required")
    .matches(/^[0-9]+$/, "Account number must be numeric")
    .min(9, "Account number must be at least 9 digits long")
    .max(18, "Account number must be less than 19 digits"),
  ifsc: yup
    .string()
    .required("IFSC code is required")
    .matches(/^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/, "Invalid IFSC code"),
  branchName: yup
    .string()
    .required("Branch name is required")
    .min(3, "Branch name must be at least 3 characters long"),
});
