import MagnifyingGlassIcon from "@heroicons/react/24/solid/MagnifyingGlassIcon";
import { Card, InputAdornment, OutlinedInput, SvgIcon } from "@mui/material";
import { Stack } from "@mui/system";

export const SearchInput = ({ placeholder, keyword, setKeyword, noPadd }) => (
  <Stack sx={{ p: noPadd ? 0 : 2, pl: 0 }}>
    <OutlinedInput
      value={keyword}
      onChange={(e) => setKeyword(e.target.value)}
      fullWidth
      placeholder={placeholder}
      startAdornment={
        <InputAdornment position="start">
          <SvgIcon color="action" fontSize="small">
            <MagnifyingGlassIcon />
          </SvgIcon>
        </InputAdornment>
      }
      sx={{ maxWidth: 500, height: 40, backgroundColor: "#fff" }}
    />
  </Stack>
);
