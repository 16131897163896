import * as React from "react";
import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import {
  AvatarGroup,
  Grid,
  Stack,
  List,
  ListItem,
  ListItemText,
  TextField,
  FormHelperText,
} from "@mui/material";
import { connect } from "react-redux";
import moment from "moment/moment";
import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/system";

import { createTasks, updateTask } from "store/task/taskactions";
import { Download, LinkOutlined } from "@mui/icons-material";
import { Card, InputAdornment, OutlinedInput, SvgIcon } from "@mui/material";
import { indigo } from "theme/colors";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  ".MuiDialog-paper": {
    borderRadius: 0,
    minWidth: "35%",
  },
  "& .MuiDialogContent-root": {
    borderTop: `0px solid #F2F4F7`,
  },

  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function Acceptance({ setOpen, open, type, viewID, getdata, item, err }) {
  const [activeStep, setActiveStep] = React.useState(0);

  const [isloading, setLoading] = React.useState(false);
  const [url, setUrl] = React.useState(
    viewID?.completionStatus == "done" ? viewID?.submittedUrls?.[0] : ""
  );
  const [errors, setError] = React.useState("");

  const [showCat, setShowCat] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      setOpen(false);
      setActiveStep(0);
    }
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          sx={{
            fontSize: "22px",
          }}
        >
          {err ? `Task expired` : "Task Acceptance Confirmation"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Typography variant="smallcontent" gutterBottom>
            {err
              ? `Deadline passed`
              : `Please remember that completing the task and submitting the required
            proof before the deadline is essential for successful task
            fulfillment. Ensure you review all task requirements and plan
            accordingly to meet the deadline.`}{" "}
          </Typography>
          {!err && (
            <Stack
              direction={"row"}
              mt={2}
              mb={2}
              justifyContent={"flex-start"}
            >
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={`/img/timer.png`}
                  style={{ width: "25px", height: "25px" }}
                />
                <Typography
                  color={"#FFA726"}
                  sx={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    marginLeft: "5px",
                  }}
                >
                  Deadline:{" "}
                </Typography>
                <Typography
                  color={"#636E72"}
                  sx={{
                    fontWeight: "700",
                    fontSize: "12px",
                    marginLeft: "5px",
                  }}
                >
                  {moment(viewID?.task?.endDate).format("DD/MM/YYYY")}{" "}
                  {moment(viewID?.task?.endDate).format("hh:mm: a")}
                </Typography>
              </div>
            </Stack>
          )}

          {
            <Grid
              container
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              mt={4}
            >
              <LoadingButton
                onClick={handleClose}
                sx={{ width: "200px", alignSelf: "center" }}
                variant={"contained"}
              >
                Ok
              </LoadingButton>
            </Grid>
          }
        </DialogContent>

        <DialogActions></DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  updateTaskstatus: (data, id, callback) =>
    dispatch(updateTask(data, id, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Acceptance);
