import React, { useRef } from "react";
import {
  Box,
  TextField,
  Typography,
  FormHelperText,
  Grid,
  Stack,
} from "@mui/material";
const FileTextInput = ({
  icon,
  type,
  placeholder,
  label,
  name,
  maxLength,
  isInvalid,
  bottomSpace,
  onBlur,
  onChange,
  value,
  error,
  readOnly,
  iconClick,
  disabled,
  handleBlur,
  cancel,
  onCancel,
  custom,
  accept,
  multiple,
}) => {
  const fileInputRef = useRef(null);

  return (
    <Box sx={{ mb: bottomSpace ? 0 : 3 }}>
      <Typography variant="label">{label}</Typography>
      <Grid
        container
        sx={{
          borderRadius: "6px",
          backgroundColor: "#F1F4F9",
          height: "40px",
          padding: "10px 14px",
          marginTop: "5px",
        }}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Grid lg={8} xs={9}>
          <Typography
            sx={{
              color: "#AAB6C1",
              fontFamily: "Poppins",
              fontSize: "12px",
              fontWeight: "400",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {" "}
            {value ? value?.name : "Upload File"}
          </Typography>
        </Grid>
        <Grid xs={3} lg={4}>
          <Stack
            direction={cancel ? "row" : "column"}
            justifyContent={"space-between"}
          >
            <Typography
              onClick={() => {
                fileInputRef.current.click();
              }}
              sx={{
                color: "rgba(26, 26, 26, 0.6)",
                fontFamily: "Poppins",
                fontSize: "10px",
                fontWeight: "700",
                cursor: "pointer",
                textAlign: cancel ? "left" : "right",
                marginRight: "5px",
              }}
            >
              {cancel ? "Select file" : "Upload"}
            </Typography>

            {cancel && (
              <Typography
                onClick={() => {
                  onCancel();
                }}
                sx={{
                  color: "rgba(26, 26, 26, 0.6)",
                  fontFamily: "Poppins",
                  fontSize: "10px",
                  fontWeight: "700",
                  cursor: "pointer",
                  textAlign: "right",
                }}
              >
                Cancel
              </Typography>
            )}
          </Stack>
        </Grid>
      </Grid>
      <input
        type="file"
        accept={accept}
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={(e) => {
          if (multiple)
            onChange.setFieldValue(name, [
              ...Array.from(value),
              ...Array.from(e.currentTarget.files),
            ]);
          else if (custom) {
            onChange(e);
          } else {
            onChange.setFieldValue(name, e.currentTarget.files[0]);
          }
        }}
        name={name}
        multiple={multiple}
      />

      {error && (
        <FormHelperText sx={{ color: "#F04438", marginLeft: "14px" }}>
          {error}
        </FormHelperText>
      )}
    </Box>
  );
};

export default FileTextInput;
