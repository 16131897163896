import React, { useEffect } from "react";
import { Layout } from "common/dashboard/layout";
import { Stack, Grid, Typography, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getmyCount } from "store/auth/authActions";

function Dashboard() {
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(getmyCount());
    window.scrollTo(0, 0);
  }, []);
  let counts = useSelector((s) => s.auth?.counts || undefined);

  const Boxdesign = ({ img, value, title, backgroundColor }) => (
    <Stack
      direction="row"
      sx={{
        backgroundColor,
        borderRadius: "14px",
        p: { lg: 4, xs: 2 },
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Stack sx={{ width: "15%" }}>
        <Stack
          style={{
            backgroundColor: "white",
            width: 88,
            height: 88,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            borderRadius: 88,
          }}
        >
          <img
            src={img}
            style={{ width: 40, height: 40, objectFit: "contain" }}
          />
        </Stack>
      </Stack>
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        ml={2}
        sx={{ width: "70%" }}
      >
        <Typography fontSize={"16px"} color={"#7E92A2"}>
          {title}
        </Typography>
        <Typography fontWeight={"bold"} fontSize={"40px"}>
          {value}
        </Typography>
      </Stack>
    </Stack>
  );
  return (
    <div className="bg_wrapper">
      <Layout>
        <Box
          sx={{
            backgroundColor: "#f5f5f5",
            p: 0,
            m: 0,
            minHeight: "100vh",
          }}
        >
          {counts?.allAssignedTaskCount == 0 ? (
            <Grid
              container
              justifyContent={"center"}
              className="white-box"
              alignItems={"center"}
              sx={{
                py: { xs: "30px ", sx: "20px", md: "30px" },
                px: { xs: "10px", sx: "10px", md: "10px" },
                mt: 6,
              }}
            >
              <Grid lg={11} alignSelf={"center"}>
                <Typography variant="h4">Congratulation, </Typography>
              </Grid>
              <Grid lg={11} alignSelf={"center"} mt={2}>
                <Typography variant="subtitle2" textAlign={"center"}>
                  Your account has been approved.
                </Typography>
                <Typography variant="subtitle2" textAlign={"center"}>
                  Further steps will be informed over registered email and sms.
                </Typography>
                <Typography variant="subtitle2" textAlign={"center"}>
                  You can also login to check your status here.{" "}
                </Typography>
              </Grid>
              <Grid lg={5} alignSelf={"center"} mt={4}>
                <img src="img/congrat.svg" style={{ marginTop: 5 }} />
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
              spacing={4}
              sx={{
                py: { xs: "30px ", sx: "20px", md: "30px" },
                px: { xs: "10px", sx: "10px", md: "30px" },
              }}
            >
              <Grid lg={4} xs={11} alignSelf={"center"} item>
                <Boxdesign
                  img={"/img/taskplus.png"}
                  title={"Assigned Task"}
                  backgroundColor={"#FFF1D3"}
                  value={counts?.allAssignedTaskCount}
                />
              </Grid>
              <Grid lg={4} xs={11} alignSelf={"center"} item>
                <Boxdesign
                  img={"img/taskp.png"}
                  backgroundColor={"#FFC4C4"}
                  title={"Pending Task"}
                  value={counts?.inProgressAssignedTaskCount}
                />
              </Grid>

              <Grid lg={4} xs={11} alignSelf={"center"} item>
                <Boxdesign
                  img={"img/taskt.png"}
                  title={"Total Task"}
                  backgroundColor={"#E6EDF4"}
                  value={counts?.allAssignedTaskCount}
                />
              </Grid>
              <Grid lg={4} xs={11} alignSelf={"center"} item>
                <Boxdesign
                  img={"/img/taskdone.png"}
                  title={"Completed Task"}
                  value={counts?.completedAssignedTaskCount}
                  backgroundColor={"#C0E4DE"}
                />
              </Grid>
              <Grid lg={4} xs={11} alignSelf={"center"} item>
                <Boxdesign
                  img={"img/taskstar.png"}
                  title={"My Own Content"}
                  value={counts?.content?.allContentCount}
                  backgroundColor={"#D2D2D4"}
                />
              </Grid>

              <Grid lg={4} xs={11} alignSelf={"center"} item>
                <Boxdesign
                  img={"img/taskincom.png"}
                  title={"Laps Task"}
                  value={counts?.lapseAssignedTaskCount}
                  backgroundColor={"#EFD9CF"}
                />
              </Grid>
            </Grid>
          )}
        </Box>
      </Layout>
    </div>
  );
}

export default Dashboard;
