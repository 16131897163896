import React, { useEffect, useState } from "react";
import { Layout } from "common/dashboard/layout";
import { Stack, Grid, Typography, Box, Container } from "@mui/material";
import { NoTaskCard } from "common/NoTaskCard";
import { gettask } from "store/task/taskactions";
import { connect } from "react-redux";
import { TaskCard } from "common/TaskCard";
import ViewTask from "common/modal/ViewTask";

function Tasks({ taskData, gettask }) {
  useEffect(() => {
    window.scrollTo(0, 0);
    gettask({
      sort: "createdAt",
      order: "desc",
    });
  }, []);
  const [view, setView] = useState(false);
  const [viewID, setViewID] = useState("");

  return (
    <div>
      <Layout>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 5,
            px: 2,
            backgroundColor: "#e6e7e9",
            minHeight: "100vh",
          }}
        >
          <Container maxWidth="xl">
            <Stack sx={{ mb: "1.5rem" }}>
              <Grid container justifyContent={"center"} spacing={3}>
                {[
                  {
                    label: "To do",
                    value: "todo",
                  },
                  {
                    label: "In Progress",
                    value: "inprogress",
                  },
                  {
                    label: "Done",
                    value: "done",
                  },
                ]?.map((i) => {
                  let list = taskData?.filter(
                    (o) => o.completionStatus == i?.value
                  );
                  if (i?.value == "done") {
                    let extra = taskData?.filter(
                      (o) => o.completionStatus == "expired"
                    );
                    list = [...list, ...extra];
                  }
                  return (
                    <Grid xs={12} sm={6} md={4} lg={4} item>
                      {list?.length == 0 ? (
                        <NoTaskCard title={i.label} />
                      ) : (
                        <TaskCard
                          setView={setView}
                          list={list}
                          title={i.label}
                          setViewID={setViewID}
                        />
                      )}
                    </Grid>
                  );
                })}
              </Grid>
            </Stack>
          </Container>
        </Box>
        {view && (
          <ViewTask
            open={view}
            getdata={gettask}
            setOpen={setView}
            viewID={viewID}
          />
        )}
      </Layout>
    </div>
  );
}

const mapStateToProps = (state) => ({
  taskData: state.task.taskData,
});

const mapDispatchToProps = (dispatch) => ({
  gettask: (data, callback) => dispatch(gettask(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Tasks);
