import PropTypes from "prop-types";
import { Box, Button, Container, SvgIcon, Typography } from "@mui/material";

export const EmptyRecordContent = (props) => {
  return (
    <Container sx={{ padding: 3 }}>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            mb: 3,
            textAlign: "center",
          }}
        >
          <img
            alt="NO Content development"
            src="/img/nocontent.png"
            style={{
              display: "inline-block",
              maxWidth: "100%",
              width: 300,
            }}
          />
        </Box>
        <Typography align="center" sx={{ mb: 3 }} variant="h3">
          No Own Content added{" "}
        </Typography>
        <Typography align="center" sx={{ mb: 3 }} variant="contentdesc">
          No own Content has been added yet.{" "}
        </Typography>
      </Box>
    </Container>
  );
};

EmptyRecordContent.propTypes = {
  buttonText: PropTypes.string,
  redirect: PropTypes.string,
};
