import * as React from "react";
import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import {
  AvatarGroup,
  Grid,
  Stack,
  List,
  ListItem,
  ListItemText,
  TextField,
  FormHelperText,
} from "@mui/material";
import { connect } from "react-redux";
import moment from "moment/moment";
import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/system";

import { createTasks, updateTask } from "store/task/taskactions";
import { Download, LinkOutlined } from "@mui/icons-material";
import { Card, InputAdornment, OutlinedInput, SvgIcon } from "@mui/material";
import { indigo } from "theme/colors";
import Acceptance from "./Acceptance";
import { useFormik } from "formik";
import FileTextInput from "common/FileTextInput";
import { submitproofValidationSchema } from "utils/schema";
import api from "store/api/api";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  ".MuiDialog-paper": {
    borderRadius: 0,
    minWidth: "35%",
  },
  "& .MuiDialogContent-root": {
    borderTop: `0px solid #F2F4F7`,
  },

  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function ViewTask({
  setOpen,
  open,
  type,
  viewID,
  getdata,
  item,
  updateTaskstatus,
}) {
  const [activeStep, setActiveStep] = React.useState(0);

  const [showErr, setshowErr] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      proof: "",
      url: "",
    },
    validationSchema: submitproofValidationSchema,
    onSubmit: async (values, helpers) => {
      try {
        setLoading(true);
        let formdata = new FormData();
        formdata.append("files", values.proof);
        let submittedurl = await api.post(
          `/assignedTask/uploadFiles/assignedTask/${viewID?._id}`,
          formdata
        );
        updateTaskstatus(
          {
            submittedUrls: [values.url],
            completionStatus: "done",
            submittedDocuments: submittedurl?.data?.data,
          },
          viewID?._id,
          (e) => {
            setOpen(false);
            getdata();
            setLoading(false);
          }
        );
      } catch (err) {
        setLoading(false);
      }
    },
  });

  const [isloading, setLoading] = React.useState(false);
  const [url, setUrl] = React.useState(
    viewID?.completionStatus == "done" ? viewID?.submittedUrls?.[0] : ""
  );
  const [errors, setError] = React.useState("");
  const [touched, settouch] = React.useState(false);

  const [showCat, setShowCat] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      setOpen(false);
      setActiveStep(0);
      setLoading(false);
    }
  };

  const handleUpdate = () => {
    settouch(true);
    if (viewID?.completionStatus == "todo") {
      setLoading(true);
      updateTaskstatus(
        {
          completionStatus: "inprogress",
        },
        viewID?._id,
        (ERR) => {
          console.log(ERR);
          if (ERR.err) {
            setshowErr(true);
            getdata();
          } else {
          }
          getdata();

          setShowCat(true);
          setLoading(false);
        }
      );
    }
  };

  React.useEffect(() => {
    formik.resetForm();
  }, [open]);

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          sx={{
            fontSize: "22px",
          }}
        >
          {viewID?.task?.title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Typography variant="smallcontent" gutterBottom>
            {viewID?.task?.description}
          </Typography>
          <Stack direction={"row"} mt={2} mb={2} justifyContent={"flex-start"}>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={`/img/timer.png`}
                style={{ width: "25px", height: "25px" }}
              />
              <Typography
                color={"#FFA726"}
                sx={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  marginLeft: "5px",
                }}
              >
                Deadline:{" "}
              </Typography>
              <Typography
                color={"#636E72"}
                sx={{
                  fontWeight: "700",
                  fontSize: "12px",
                  marginLeft: "5px",
                }}
              >
                {moment(viewID?.task?.endDate).format("DD/MM/YYYY")}{" "}
                {moment(viewID?.task?.endDate).format("hh:mm: a")}
              </Typography>
            </div>
          </Stack>
          <Box mt={2}>
            <Typography variant="bhead">{"Attachment"}</Typography>
          </Box>
          <List>
            {viewID?.task?.media?.map((file, index) => {
              let it = file?.original?.split("/");
              return (
                <ListItem key={index}>
                  {file?.original?.match(/\.(jpeg|jpg|gif|png|svg)$/i) !=
                    null && (
                    <img
                      src={`${process.env.REACT_APP_IMG}${file?.original}`}
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "10px",
                      }}
                    ></img>
                  )}
                  <ListItemText secondary={it?.[it?.length - 1]} />

                  <a
                    onClick={() => {
                      window.open(
                        `${process.env.REACT_APP_IMG}${file?.original}`,
                        "_blank"
                      );
                    }}
                  >
                    <Download />
                  </a>
                </ListItem>
              );
            })}
          </List>
          <form noValidate onSubmit={formik.handleSubmit}>
            {viewID?.completionStatus == "inprogress" && (
              <Box mt={2}>
                <Typography variant="bhead" gutterBottom>
                  {"Upload Url"}
                </Typography>
                <OutlinedInput
                  fullWidth
                  disabled={viewID?.completionStatus == "done"}
                  sx={{ mt: 1 }}
                  name={"url"}
                  placeholder="Upload link"
                  onChange={(e) => formik.setFieldValue("url", e.target.value)}
                  value={
                    viewID?.completionStatus == "done" ? url : formik.values.url
                  }
                  error={formik.touched.url ? formik.errors.url : ""}
                  startAdornment={
                    <InputAdornment position="start">
                      <SvgIcon color={indigo.main} fontSize="small">
                        <LinkOutlined />
                      </SvgIcon>
                    </InputAdornment>
                  }
                />
                {formik.touched.url && (
                  <FormHelperText className="text-danger" mt={1} ml="1">
                    {formik.errors.url}
                  </FormHelperText>
                )}
              </Box>
            )}
            {viewID?.completionStatus == "done" && (
              <Box mt={2}>
                <Typography variant="bhead" gutterBottom>
                  {"Upload Url"}
                </Typography>
                <OutlinedInput
                  fullWidth
                  disabled={true}
                  sx={{ mt: 1 }}
                  name={"url"}
                  placeholder="Upload link"
                  onChange={(e) => formik.setFieldValue("url", e.target.value)}
                  value={viewID?.submittedUrls?.[0]}
                  //    error={formik.touched.url ? formik.errors.url : ""}
                  startAdornment={
                    <InputAdornment position="start">
                      <SvgIcon color={indigo.main} fontSize="small">
                        <LinkOutlined />
                      </SvgIcon>
                    </InputAdornment>
                  }
                />
              </Box>
            )}

            {viewID?.completionStatus == "inprogress" && (
              <Box mt={2}>
                <Typography variant="bhead" gutterBottom>
                  {"Upload Proof"}
                </Typography>
                <FileTextInput
                  type="file"
                  placeholder="Upload File"
                  name="proof"
                  required
                  bottomSpace
                  onChange={formik}
                  value={formik.values.proof}
                  error={formik.touched.proof ? formik.errors.proof : ""}
                />
                <Box sx={{ marginLeft: "4px", marginBottom: 2 }}>
                  <Typography variant="h7">
                    {"  "} Note: All files should less than 250 MB
                  </Typography>
                </Box>
              </Box>
            )}
            {viewID?.completionStatus == "done" && (
              <Stack
                direction={"row"}
                mt={2}
                mb={2}
                justifyContent={"flex-start"}
              >
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    color={"#219653"}
                    sx={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      marginLeft: "5px",
                    }}
                  >
                    Submitted On:{" "}
                  </Typography>
                  <Typography
                    color={"#636E72"}
                    sx={{
                      fontWeight: "700",
                      fontSize: "12px",
                      marginLeft: "5px",
                    }}
                  >
                    {moment(viewID?.submittedOn).format("DD/MM/YYYY")}{" "}
                    {moment(viewID?.submittedOn).format("hh:mm: a")}
                  </Typography>
                </div>
              </Stack>
            )}
            <Grid
              container
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              mt={4}
            >
              {viewID?.completionStatus == "todo" && (
                // moment(viewID?.task?.endDate).isAfter(moment()) &&
                <LoadingButton
                  onClick={handleUpdate}
                  variant="contained"
                  loading={isloading}
                  sx={{ width: "200px", alignSelf: "center" }}
                >
                  Accept Task{" "}
                </LoadingButton>
              )}
              {viewID?.completionStatus == "inprogress" &&
                moment(viewID?.task?.endDate).isAfter(moment()) && (
                  <LoadingButton
                    onClick={handleUpdate}
                    variant="contained"
                    type="submit"
                    loading={isloading}
                    sx={{ width: "200px", alignSelf: "center" }}
                  >
                    {viewID?.completionStatus == "todo"
                      ? "Start task"
                      : "Submit"}
                  </LoadingButton>
                )}
              <LoadingButton
                onClick={handleClose}
                sx={{ width: "200px", alignSelf: "center" }}
              >
                Close
              </LoadingButton>
            </Grid>
          </form>
        </DialogContent>

        <DialogActions></DialogActions>
        <Acceptance
          viewID={viewID}
          setOpen={() => {
            handleClose();
            setShowCat(false);
          }}
          err={showErr}
          open={showCat}
        />
      </BootstrapDialog>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  updateTaskstatus: (data, id, callback) =>
    dispatch(updateTask(data, id, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ViewTask);
