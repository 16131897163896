import React, { useEffect } from "react";
import Header from "common/header/header";
import Footer from "common/footer";
import Background from "common/Background";
import {
  Grid,
  Typography,
  FormControl,
  FormHelperText,
  Button,
} from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import { registerValidationSchema } from "utils/schema";
import CloudUploadIcon from "@mui/icons-material/FileOpenOutlined";
import { useNavigate } from "react-router-dom";

function Register() {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const formik = useFormik({
    initialValues: {
      is18: false,
      is15: false,
      sixty: false,
    },
    validationSchema: registerValidationSchema,
    onSubmit: async (values, helpers) => {
      try {
      } catch (err) {}
    },
  });
  return (
    <div className="bg_wrapper">
      <Background>
        <div className="white-box">
          <Grid
            container
            sx={{
              pt: { xs: "10px", md: "25px", lg: "80px" },
              pb: { xs: "10px", md: "50px", lg: "50px" },
              pr: { xs: "10px", md: "55px", lg: "100px" },
              pl: { xs: "10px", md: "40px", lg: "40px" },
              backgroundImage: `url('img/back4.svg')`,
              backgroundPosition: `center center`,
              backgroundSize: "contain",
              borderRadius: `21.529px`,
              position: "relative",
            }}
          >
            <Grid
              lg={6}
              md={6}
              alignItems={"flex-start"}
              sx={{ margin: { xs: "0 auto" } }}
            >
              <img src="img/left.png" className="register-box" />
            </Grid>
            <Grid
              lg={6}
              md={6}
              sx={{
                textAlign: "left",
                mt: { xs: 5, lg: 0 },
                pl: { md: 0, lg: 10 },
              }}
            >
              <Typography variant="title">
                Empanelment of Social Media Influencers (SMI){" "}
              </Typography>
              <Grid sx={{ pl: { lg: 4, md: 4 } }}>
                <Typography variant="subtitle1" sx={{ mt: 5 }}></Typography>
                <Typography variant="subtitle3">
                  {" "}
                  ESG intends to engage the services of Social Media Influencers
                  for promotional activity so as to create maximum awareness of
                  Government Schemes, Policies and Programs through the
                  influencer with their Social Media handles.
                </Typography>
                <br />
                <Grid sx={{ mt: 6 }}>
                  <Typography variant="subtitle1">
                    Refer to the PDF for detailed Guidelines and procedures for
                    the empanelment of SMIs.{" "}
                  </Typography>
                  <Button
                    component="label"
                    onClick={() => {
                      window.open("SMILE_RFP_V4.pdf", "_blank");
                    }}
                    variant="outlined"
                    sx={{ mt: 2, mb: 4 }}
                    startIcon={<CloudUploadIcon />}
                  >
                    Document{" "}
                  </Button>{" "}
                  <Typography sx={{ mt: 1, mb: 6 }}>
                    If you have any questions or require further clarification,
                    do not hesitate to reach out to us at empanelment@esg.co.in
                  </Typography>
                </Grid>
              </Grid>
              {/* <LoadingButton
                //loading={loading}
                fullWidth
                size="large"
                sx={{ mt: 2 }}
                onClick={() => {
                  navigate("/register?step=1");
                }}
                variant="contained"
              >
                Click To Register{" "}
              </LoadingButton> */}
              <Button
                //loading={loading}
                fullWidth
                variant="contained"
                size="large"
                sx={{ mt: 2 }}
                onClick={() => {
                  navigate("/login");
                }}
              >
                Click To Login
              </Button>
            </Grid>
          </Grid>
        </div>
      </Background>
    </div>
  );
}

export default Register;
