import {
  ArchiveOutlined,
  DashboardOutlined,
  TaskAltOutlined,
  Settings,
} from "@mui/icons-material";
import { SvgIcon } from "@mui/material";
import { Plus } from "react-bootstrap-icons";
export const items = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: (
      <SvgIcon fontSize="small">
        <DashboardOutlined />
      </SvgIcon>
    ),
  },
  {
    title: "Task",
    path: "/tasks",
    icon: (
      <SvgIcon fontSize="small">
        <TaskAltOutlined />
      </SvgIcon>
    ),
  },
  {
    title: "Add Own Content",
    path: "/owncontent",
    icon: (
      <SvgIcon fontSize="small">
        <Plus />
      </SvgIcon>
    ),
  },
  {
    title: "Payments",
    path: "/payments",
    icon: (
      <SvgIcon fontSize="small">
        <ArchiveOutlined />
      </SvgIcon>
    ),
  },
  {
    title: "Settings",
    path: "/settings",
    icon: (
      <SvgIcon fontSize="small">
        <Settings />
      </SvgIcon>
    ),
  },
];
