import { configureStore, combineReducers } from "@reduxjs/toolkit";
// Import reducer slices
import auth from "store/auth/authSlices";
import task from "store/task/taskSlices";

import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

// Import other reducer slices as needed
const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["auth", "task"],
};
const rootReducer = combineReducers({
  auth,
  task,
  // Add other reducers here...
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable serializable check
    }),
});

const persistor = persistStore(store);

export { persistor, store };
