import React, { useEffect } from "react";
import Register from "../register/register";
import { useNavigate } from "react-router-dom";

function Home() {
  const router = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    let auth = localStorage.getItem("auth");
    if (auth) {
      router("/dashboard");
    }
  }, []);
  return (
    <div className="bg_wrapper">
      <Register />
    </div>
  );
}

export default Home;
