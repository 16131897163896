import React, { useEffect, useState } from "react";
import Header from "common/header/header";
import Footer from "common/footer";
import Background from "common/Background";
import {
  Grid,
  Typography,
  FormControl,
  FormHelperText,
  Box,
  Button,
  IconButton,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Stack } from "react-bootstrap";
import TextInput from "common/TextInput";
import { AttachFile } from "@mui/icons-material";
import FileTextInput from "common/FileTextInput";
import api from "store/api/api";

function SeekDetails() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const userData = useSelector((i) => i?.auth?.userData);
  const [Answer, setAnswer] = useState([]);
  const [loading, setloading] = useState(false);
  const [touched, settouched] = useState(false);

  useEffect(() => {
    let qa = [];
    userData?.moreDetails?.map((i) => {
      qa.push({
        _id: i?._id,
        answer: "",
        showfile: false,
        file: "",
      });
    });
    setAnswer(() => [...qa]);
  }, [userData || ""]);
  const handleSumbit = async () => {
    try {
      settouched(true);
      let promises = [];
      Answer?.map((i) => {
        if (i?.answer != "") {
          const promise = api.put(`/user/moreDetail/answer/${i?._id}`, {
            answer: i?.answer,
          });
          promises.push(promise);
        }
        if (i?.showfile && i?.file != "") {
          const formData = new FormData();
          formData.append("file", i?.file);
          let promise2 = api.post(
            `/user/moreDetail/answerUpload/${i?._id}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );

          promises.push(promise2);
        }
      });
      if (promises?.length != 0) {
        setloading(true);
      }
      let result = await Promise.all(promises);
      let movenext = true;
      let errorMsg = "";
      result?.map((i) => {
        if (i?.response?.data?.error) {
          movenext = false;
          errorMsg = i?.response?.data?.message;
        }
      });
      setloading(false);
      if (movenext) {
        navigate("/registerdone");
      } else {
        alert(errorMsg);
      }
    } catch {}
  };
  return (
    <div className="bg_wrapper">
      <Background>
        <div className="white-box2">
          <Grid
            container
            sx={{
              pt: { xs: "10px", md: "25px", lg: "50px" },
              pb: { xs: "10px", md: "50px", lg: "50px" },
              pr: { xs: "10px", md: "55px", lg: "100px" },
              pl: { xs: "10px", md: "40px", lg: "40px" },
              backgroundSize: "contain",
              borderRadius: `21.529px`,
              position: "relative",
              minHeight: "60vh",
            }}
            justifyContent="center"
          >
            <Grid
              lg={8}
              xs={11}
              sx={{
                mt: { xs: 5, lg: 0 },
                pl: { md: 0, lg: 10 },
              }}
            >
              <Typography variant="title">Clarification Submission</Typography>
              <Grid textAlign={"left"} mt={3}>
                <Typography variant="subtitle2">
                  Your application for empanelment is currently under review,
                  and in order to proceed with the approval process, we require
                  further clarification. Please carefully review the questions
                  or points raised and submit your detailed responses below.
                </Typography>
              </Grid>
              <Grid textAlign={"left"} sx={{ mt: 2 }}>
                {userData?.moreDetails?.map((itm, index) => {
                  return (
                    <Box>
                      <Typography variant="label">{itm?.question}</Typography>
                      <Stack direction="row" style={{ position: "relative" }}>
                        <TextInput
                          placeholder={"Answer"}
                          required
                          error={
                            touched && Answer?.[index]?.answer == ""
                              ? "Enter answer"
                              : ""
                          }
                          onChange={(e) => {
                            Answer[index].answer = e.target.value;
                            setAnswer(() => [...Answer]);
                          }}
                          value={Answer?.[index]?.answer}
                        ></TextInput>
                        <IconButton
                          onClick={() => {
                            Answer[index].showfile = true;
                            setAnswer(() => [...Answer]);
                          }}
                          sx={{ position: "absolute", right: 10, top: "10px" }}
                        >
                          <AttachFile />
                        </IconButton>
                      </Stack>
                      {Answer?.[index]?.showfile && (
                        <FileTextInput
                          custom
                          cancel
                          error={
                            touched && Answer?.[index]?.file == ""
                              ? "Select file or click cancel"
                              : ""
                          }
                          value={Answer?.[index]?.file}
                          onChange={(e) => {
                            Answer[index].file = e.currentTarget.files[0];
                            setAnswer(() => [...Answer]);
                          }}
                          onCancel={(e) => {
                            Answer[index].showfile = false;
                            setAnswer(() => [...Answer]);
                          }}
                        />
                      )}
                    </Box>
                  );
                })}
              </Grid>
              <Typography variant="h7" lineHeight={"10px"}>
                Attach any relevant documents or evidence that supports your
                clarification if needed.
              </Typography>

              <LoadingButton
                sx={{ mt: 2 }}
                loading={loading}
                fullWidth
                variant={"contained"}
                onClick={() => handleSumbit()}
              >
                Submit
              </LoadingButton>
            </Grid>
          </Grid>
        </div>
      </Background>
    </div>
  );
}

export default SeekDetails;
