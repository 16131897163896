import React, { useEffect, useState, useRef } from "react";
import { Layout } from "common/dashboard/layout";
import {
  Container,
  Grid,
  Table,
  Typography,
  Button,
  Avatar,
  Box,
  Chip,
} from "@mui/material";
import SelectSearch from "common/select";
import moment from "moment";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import { connect } from "react-redux";
import { getAccounts } from "store/task/taskactions";
import TextInput from "common/TextInput";
import { paymentSchema, registerValidationSchema } from "utils/schema";
import { UploadFile } from "@mui/icons-material";
import api from "store/api/api";
import { getDetails, updatePaymentDetails } from "store/auth/authActions";

function Settings({ userData, getDetails, updatePaymentDetails }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const fileInputRef = useRef(null);
  let { accountDetail } = userData || {};

  let { accountHolderName, bankName, accountNumber, ifsc, branchName } =
    accountDetail || {};

  const [keyword, setKeyword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const formik = useFormik({
    initialValues: {
      accountHolderName: accountHolderName || "",
      bankName: bankName || "",
      accountNumber: accountNumber || "",
      ifsc: ifsc || "",
      branchName: branchName || "",
    },
    validationSchema: paymentSchema,
    onSubmit: async (values, helpers) => {
      try {
        setLoading(true);
        setError("");
        updatePaymentDetails(
          {
            accountDetail: { ...values },
          },
          async (res) => {
            setLoading(false);
            if (!res?.err) {
            }
            if (res?.err) {
              setError(res?.message);
            }
          }
        );
      } catch (err) {
        setLoading(false);
      }
    },
  });

  const statusMap = {
    pending: "#3211FF",
    approved: "#00B087",
    rejected: "#DF0404",
    needMoreDetail: "#FD884B",
  };
  const statusMapBC = {
    pending: "#C5E8FC",
    approved: "#A6E7D8",
    rejected: "#FFC5C5",
    needMoreDetail: "#FED2BB",
  };
  const [params, setParams] = useState({
    sort: "createdAt",
    order: "desc",
  });

  return (
    <div className="bg_wrapper">
      <Layout>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 5,
            px: 2,
            backgroundColor: "#e6e7e9",
            minHeight: "100vh",
          }}
        >
          <Container maxWidth="xl">
            <Box
              sx={{
                minWidth: 800,
                px: 4,
                backgroundColor: "#fff",
                borderRadius: "10px",
                pb: 4,
              }}
            >
              <Grid
                container
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Grid item lg={7}>
                  <Typography variant="h5" gutterBottom>
                    {"Basic Info"}
                  </Typography>
                  <Typography variant="label">{"Full Name"}</Typography>
                  <Grid container justifyContent={"space-between"}>
                    <Grid lg={6}>
                      <TextInput
                        type="text"
                        placeholder="First Name"
                        label=""
                        name="firstname"
                        // onChange={formik.handleChange}
                        value={userData?.firstName}
                        error={
                          formik.touched.firstname
                            ? formik.errors.firstname
                            : ""
                        }
                      />
                    </Grid>
                    <Grid lg={5} textAlign={"right"}>
                      <TextInput
                        type="text"
                        placeholder="Last Name"
                        label=" "
                        name="lastname"
                        //  onChange={formik.handleChange}
                        value={userData?.lastName}
                        error={
                          formik.touched.lastname ? formik.errors.lastname : ""
                        }
                      />
                    </Grid>
                    <TextInput
                      type="text"
                      placeholder="Last Name"
                      label="Phone Number"
                      name="mobile"
                      //  onChange={formik.handleChange}
                      value={userData?.mobile}
                      error={formik.touched.mobile ? formik.errors.mobile : ""}
                    />
                    <TextInput
                      type="text"
                      placeholder="Email"
                      label="Email"
                      name="email"
                      //  onChange={formik.handleChange}
                      value={userData?.email}
                      error={formik.touched.email ? formik.errors.email : ""}
                    />
                  </Grid>
                </Grid>
                <Grid item lg={3}>
                  <Box sx={{ p: 3, backgroundColor: "#F5F7FA" }}>
                    {userData?.profilePicture == null ? (
                      <Avatar
                        sx={{
                          bgcolor: "#FD884B",
                          cursor: "pointer",
                          height: 190,
                          width: "100%",
                          borderRadius: 0,
                          textTransform: "uppercase",
                          marginBottom: 2,
                        }}
                      >
                        {userData?.firstName?.[0]}
                      </Avatar>
                    ) : (
                      <div>
                        <img
                          src={`${process.env.REACT_APP_IMG}${userData?.profilePicture?.original}`}
                          style={{
                            bgcolor: "#FD884B",
                            cursor: "pointer",
                            height: 190,
                            width: "100%",
                            borderRadius: 0,
                            marginBottom: 2,
                          }}
                        ></img>
                      </div>
                    )}
                    <input
                      type="file"
                      accept="image/*"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={async (e) => {
                        let formdata = new FormData();
                        formdata.append("file", e.currentTarget.files[0]);

                        let fileresponse = await api.post(
                          "/user/uploadFile/profilePicture",
                          formdata
                        );
                        getDetails({}, () => {});
                        //
                      }}
                    />
                    <Button
                      variant="text"
                      onClick={() => {
                        fileInputRef.current.click();
                      }}
                      fullWidth
                      startIcon={<UploadFile />}
                    >
                      Upload Photo
                    </Button>
                    <Typography>
                      Image size should be under 1MB and image ration needs to
                      be 1:1
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                minWidth: 800,
                px: 4,
                backgroundColor: "#fff",
                borderRadius: "10px",
                pb: 4,
                mt: 3,
              }}
            >
              <Grid container>
                <Grid item lg={11}>
                  <form
                    noValidate
                    onSubmit={formik.handleSubmit}
                    style={{ marginTop: "25px" }}
                  >
                    <Typography variant="h5" gutterBottom>
                      {"Account Info"}
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item lg={6}>
                        <Typography variant="label">{"Full Name"}</Typography>
                        <TextInput
                          type="text"
                          placeholder="Full Name"
                          label=""
                          name="accountHolderName"
                          onChange={formik.handleChange}
                          value={formik.values.accountHolderName}
                          error={
                            formik.touched.accountHolderName
                              ? formik.errors.accountHolderName
                              : ""
                          }
                        />
                      </Grid>
                      <Grid item lg={6}></Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item lg={6}>
                        <Typography variant="label">{"Bank Name"}</Typography>
                        <TextInput
                          type="text"
                          placeholder="Bank Name"
                          label=""
                          name="bankName"
                          onChange={formik.handleChange}
                          value={formik.values.bankName}
                          error={
                            formik.touched.bankName
                              ? formik.errors.bankName
                              : ""
                          }
                        />
                      </Grid>
                      <Grid item lg={6}>
                        <Typography variant="label">{"Branch Name"}</Typography>
                        <TextInput
                          type="text"
                          placeholder="Branch Name"
                          label=""
                          name="branchName"
                          onChange={formik.handleChange}
                          value={formik.values.branchName}
                          error={
                            formik.touched.branchName
                              ? formik.errors.branchName
                              : ""
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item lg={6}>
                        <Typography variant="label">{"Account No"}</Typography>
                        <TextInput
                          type="text"
                          placeholder="Account Number"
                          label=""
                          name="accountNumber"
                          onChange={formik.handleChange}
                          value={formik.values.accountNumber}
                          error={
                            formik.touched.accountNumber
                              ? formik.errors.accountNumber
                              : ""
                          }
                        />
                      </Grid>
                      <Grid item lg={6}>
                        <Typography variant="label">{"Branch IFSC"}</Typography>
                        <TextInput
                          type="text"
                          placeholder="Branch IFSC"
                          label=""
                          name="ifsc"
                          onChange={formik.handleChange}
                          value={formik.values.ifsc}
                          error={formik.touched.ifsc ? formik.errors.ifsc : ""}
                        />
                      </Grid>
                    </Grid>
                    {error != "" && (
                      <Typography className="text-danger">{error}</Typography>
                    )}
                    <LoadingButton
                      loading={loading}
                      fullWidth
                      size="large"
                      sx={{ mt: 2 }}
                      type="submit"
                      variant="contained"
                    >
                      Save Details{" "}
                    </LoadingButton>{" "}
                  </form>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      </Layout>
    </div>
  );
}

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
});

const mapDispatchToProps = (dispatch) => ({
  getDetails: (data, callback) => dispatch(getDetails(data, callback)),

  updatePaymentDetails: (data, callback) =>
    dispatch(updatePaymentDetails(data, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Settings);
