import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    jwt: null,
    name: null,
    email: null,
    userData: null,
    loading: false,
    errors: null,
    stepcompleted: 0,
    category: [],
  },
  reducers: {
    authTriggered: (state, action) => {
      state.loading = true;
    },
    authSuccess: (state, action) => {
      state.jwt = action.payload.jwtToken;
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.loading = false;
      state.validUser = true;
    },
    registerDataSuccess: (state, action) => {
      state.registerData = { ...state.registerData, ...action.payload };
    },
    updateStepCompleted: (state, action) => {
      state.stepcompleted = action.payload;
      if (action.payload == 6) {
        state.registerData = {};
        state.stepcompleted = 0;
      }
    },
    registerSuccess: (state, action) => {
      state.user = action.payload;
    },
    categorySuccess: (state, action) => {
      state.category = action.payload;
    },
    authFailure: (state, action) => {
      state.errors = action.payload;
      state.loading = false;
    },
    logoutUser: (state) => {
      state.name = null;
      state.email = null;
      state.jwt = null;
      state.loading = false;
      state.validUser = false;
      state.userData = null;
    },
    getUserSuccess: (state, action) => {
      state.userData = action.payload;
    },
    getCountSuccess: (state, action) => {
      state.counts = action.payload;
    },
    notificationSuccess: (state, action) => {
      state.countNotification = action.payload;
    },

    notificationListSuccess: (state, action) => {
      state.Notifications = action.payload;
    },
  },
});

export const {
  authTriggered,
  authSuccess,
  authFailure,
  logoutUser,
  getUserSuccess,
  registerDataSuccess,
  registerSuccess,
  updateStepCompleted,
  categorySuccess,
  getCountSuccess,
  notificationListSuccess,
  notificationSuccess,
} = authSlice.actions;
export default authSlice.reducer;
