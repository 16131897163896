import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  FormControl,
  FormHelperText,
  Button,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { registerUser, saveRegisterData } from "store/auth/authActions";

import { MuiOtpInput } from "mui-one-time-password-input";
import { connect } from "react-redux";

import TextInput from "common/TextInput";
import { indigo } from "theme/colors";

function StepTwo({
  confirmationResult,
  handleNext,
  registerData,
  saveRegister,
}) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [loading, setLoading] = useState(false);

  const [otp, setOtp] = React.useState("");

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  const update = (otp) => {
    setLoading(true);
    try {
      window.confirmationResult
        ?.confirm(Number(otp))
        ?.then(async (res) => {
          let {
            firstname: firstName,
            lastname: lastName,
            email,
            dob,
            city,
            zip,
            mobile,
            password,
            address1,
            address2,
          } = registerData || {};
          saveRegister(
            {
              firstName,
              lastName,
              address1,
              address2,
              email,
              dob,
              city,
              zip,
              mobile: mobile?.replace(/\s/g, "").slice(-10),
              password,
            },
            2,
            (res) => {
              if (!res.err) {
                handleNext();
                setLoading(false);
              } else {
                alert(res.msg);
                setLoading(false);
              }
            }
          );
        })
        .catch((error) => {
          alert("Invalid OTP");
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);

      alert("Invalid OTP");
      console.log(error);
    }
  };
  return (
    <Grid container justifyContent={"center"} alignItems={"center"}>
      <Grid textAlign={"center"} lg={5} xs={11} sm={8}>
        <Typography variant="title">OTP Verification</Typography>
        <div className="mb-3"></div>
        <Typography variant="subtitle2">
          Enter the code from the sms we sent to
        </Typography>
        <div className="mb-1"></div>
        <Typography variant="subtitle2" color={indigo.main}>
          {registerData?.mobile}{" "}
        </Typography>
        <Grid sx={{ mt: 8, mb: 6 }}>
          <MuiOtpInput
            value={otp}
            onChange={handleChange}
            length={6}
            type="tel"
            onComplete={(e) => update(e)}
            className="otpbox"
            onWheel={(event) => event.target.blur()}
            TextFieldsProps={{ type: "number" }}
            sx={{
              gap: { xs: "10px", md: "20px" },

              ".MuiOtpInput-Box": {},
              ".MuiOtpInput-TextField": {
                borderRadius: "4px",
                border: "2px solid #F58229",
                boxShadow: "0px 4px 8px 0px rgba(245, 130, 41, 0.16)",
                width: "50px",
                height: "50px",
              },
              ".MuiFilledInput-root": {
                borderColor: "#F58229",
                borderRadius: "2px",
              },
              ".MuiInputBase-input": {
                fontSize: "25px",
                padding: "15px",
                xs: {
                  padding: "5px",
                  width: "50px",
                  height: "50px",
                },
              },
            }}
          />
        </Grid>
        <Typography variant="subtitle2">
          I didn't receive any code.{" "}
          <span
            variant="subtitle2"
            style={{ cursor: "pointer", color: "#9899A0" }}
          >
            {" "}
            RESEND
          </span>
        </Typography>
        <LoadingButton
          loading={loading}
          fullWidth
          size="large"
          sx={{ mt: 10 }}
          onClick={() => {
            if (otp?.length == 6) {
              update(otp);
            }
          }}
          // type="submit"
          variant="contained"
        >
          Continue{" "}
        </LoadingButton>{" "}
      </Grid>
    </Grid>
  );
}
const mapStateToProps = (state) => ({
  registerData: state.auth.registerData,
});

const mapDispatchToProps = (dispatch) => ({
  saveRegister: (data, step, callback) =>
    dispatch(registerUser(data, step, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(StepTwo);
