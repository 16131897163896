import {
  Box,
  Button,
  Container,
  Stack,
  Typography,
  AvatarGroup,
  Avatar,
  Chip,
} from "@mui/material";
import moment from "moment";

export const TaskCard = ({
  title,
  setopen,
  setView,
  list,
  setselectedCategory,
  id,
  social,
  category,
  setViewID,
}) => {
  const statusMap = {
    todo: "#3211FF",
    submitted: "#00B087",
    completed: "#00B087",

    expired: "#DF0404",
    inprogress: "#FD884B",
  };
  const statusMapBC = {
    todo: "#C5E8FC",
    submitted: "#A6E7D8",
    completed: "#A6E7D8",

    expired: "#FFC5C5",
    inprogress: "#FED2BB",
  };
  return (
    <Container
      sx={{
        padding: 3,
        backgroundColor: "#EEF2F5",
        borderRadius: "10px",
        cursor: "pointer",
      }}
    >
      <Typography variant="cardHead">{title}</Typography>
      {list?.map((i, index) => (
        <Stack
          onClick={() => {
            setViewID(i);
            setView(true);
          }}
          key={index}
          sx={{ backgroundColor: "white", borderRadius: "5px" }}
          mt={2}
          mb={2}
          p={2}
        >
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography variant="boldroboto" gutterBottom>
              <img
                src={`/img/${
                  i?.completionStatus == "expired"
                    ? "inprogress"
                    : i?.completionStatus
                }.png`}
                style={{
                  width: "20px",
                  height: "20px",
                }}
              />{" "}
              {i?.task?.title}
            </Typography>
            <Chip
              label={
                i?.completionStatus == "done" &&
                i?.submissionStatus != "pending"
                  ? i?.submissionStatus == "notCompleted"
                    ? "Incomplete"
                    : i?.submissionStatus
                  : i?.completionStatus
              }
              size={"small"}
              className="capitalize"
              sx={{
                width: "30%",
                color:
                  statusMap[
                    i?.completionStatus == "done" &&
                    i?.submissionStatus != "pending"
                      ? i?.submissionStatus
                      : i?.completionStatus
                  ],
                backgroundColor:
                  statusMapBC[
                    i?.completionStatus == "done" &&
                    i?.submissionStatus != "pending"
                      ? i?.submissionStatus
                      : i?.completionStatus
                  ],
                borderRadius: "5px",
                borderStyle: "solid",
                py: 2,
              }}
            />
          </Stack>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography variant="smallcontent" color="#BDBDBD">
              {i?.task?.description}
            </Typography>
          </Stack>

          <Stack direction={"row"} mt={1} justifyContent={"space-between"}>
            <Typography></Typography>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={`/img/file_present.png`}
                style={{ width: "20px", height: "20px" }}
              />
              <Typography
                color={"#BDBDBD"}
                sx={{
                  fontWeight: "bold",
                  fontSize: "10px",
                  marginLeft: "5px",
                  marginTop: "5px",
                }}
              >
                {i?.task?.media?.length || 0}
              </Typography>
            </div>
          </Stack>

          <Stack direction={"row"} mt={1} justifyContent={"space-between"}>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={`/img/timer.png`}
                style={{ width: "20px", height: "20px" }}
              />
              <Typography
                color={"#FFA726"}
                sx={{
                  fontWeight: "bold",
                  fontSize: "10px",
                  marginLeft: "5px",
                }}
              >
                Deadline:{" "}
              </Typography>
              <Typography
                color={"#636E72"}
                sx={{
                  fontWeight: "700",
                  fontSize: "9px",
                  marginLeft: "5px",
                }}
              >
                {moment(i?.task?.endDate).format("DD/MM/YYYY")}{" "}
                {moment(i?.task?.endDate).format("hh:mm: a")}
              </Typography>
            </div>
          </Stack>
        </Stack>
      ))}
    </Container>
  );
};
