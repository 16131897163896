import React from "react";
import { Box, Select, Typography, MenuItem } from "@mui/material";
const SelectInput = ({
  icon,
  type,
  placeholder,
  label,
  name,
  maxLength,
  isInvalid,
  bottomSpace,
  onBlur,
  onChange,
  value,
  error,
  readOnly,
  iconClick,
  disabled,
  handleBlur,
  options,
}) => {
  return (
    <Box sx={{ mb: bottomSpace ? 0 : 3 }}>
      <Typography variant="label">{label}</Typography>
      <Select
        error={error}
        helperText={error}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        label="Age"
        sx={{
          "& legend": { display: "none" },
          "& fieldset": { top: 0 },
          mt: 1,
        }}
        inputProps={{
          sx: {
            "&::placeholder": {
              color: "#757D8A",
              opacity: 1, // otherwise firefox shows a lighter color
            },
            padding: "10px",
            paddingLeft: "15px",
            fontSize: "16px",
          },
        }}
        displayEmpty
        fullWidth
        name={name}
        type={type}
      >
        <MenuItem disabled value="">
          <em>{placeholder}</em>
        </MenuItem>
        {options?.map((i) => (
          <MenuItem value={i.value}>{i.label}</MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default SelectInput;
