import React, { useEffect } from "react";
import Background from "common/Background";
import { Grid } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepone from "./stepone";
import StepTwo from "./steptwo";
import StepThree from "./stepthree";
import { useNavigate, useLocation } from "react-router-dom";
import StepFour from "./stepFour";
import StepFive from "./stepFive";
import StepSix from "./stepSix";
import { connect } from "react-redux";

const steps = [1, 2, 3, 4, 5, 6];

function Registesteps({ stepcompleted }) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [confirmationResult, setconfirmationResult] = React.useState({});
  const navigate = useNavigate();

  let location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    const query = new URLSearchParams(location.search);
    let step = query.get("step");

    if (stepcompleted != undefined && stepcompleted <= Number(step + 1)) {
      step = stepcompleted + 1;
      navigate(`/register?step=${step}`);
    }
    setActiveStep(step ? Number(step) - 1 : 0);
  }, []);

  const isStepOptional = (step) => {
    return step === 1;
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    navigate(`/register?step=${activeStep + 2}`);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    navigate(`/register?step=${activeStep}`);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className="bg_wrapper">
      <Background>
        <Grid
          container
          justifyContent={"center"}
          className="white-box2"
          alignItems={"center"}
          sx={{
            py: { xs: "30px ", sx: "20px", md: "30px" },
            px: { xs: "10px", sx: "10px", md: "10px" },
          }}
        >
          {activeStep != 0 && (
            <img
              src="img/back.svg"
              alt="Background"
              onClick={handleBack}
              className="back-btn"
            />
          )}

          <Grid
            lg={12}
            sx={{ mb: "40px" }}
            justifyContent={"center"}
            alignItems={"center"}
            container
          >
            <Grid lg={5} alignSelf={"center"}>
              <Stepper activeStep={activeStep} sx={{ mb: 2 }}>
                {steps.map((label, index) => {
                  const stepProps = {};

                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel> </StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </Grid>
            {activeStep === 0 && (
              <Stepone
                setconfirmationResult={setconfirmationResult}
                handleNext={handleNext}
              />
            )}
            {activeStep === 1 && (
              <StepTwo
                confirmationResult={confirmationResult}
                handleNext={handleNext}
              />
            )}
            {activeStep === 2 && <StepThree handleNext={handleNext} />}
            {activeStep === 3 && <StepFour handleNext={handleNext} />}
            {activeStep === 4 && <StepFive handleNext={handleNext} />}
            {activeStep === 5 && <StepSix handleNext={() => {}} />}
          </Grid>
        </Grid>
        <div id="recaptcha-container"></div>
      </Background>
    </div>
  );
}

const mapStateToProps = (state) => ({
  stepcompleted: state.auth.stepcompleted,
});

export default connect(mapStateToProps, null)(Registesteps);
