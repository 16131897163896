import * as React from "react";
import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { AvatarGroup, Grid, Stack, AccordionClassKey } from "@mui/material";
import { connect } from "react-redux";
import moment from "moment/moment";
import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/system";

import { LinearProgress } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  ".MuiDialog-paper": {
    borderRadius: 0,
    minWidth: "40%",
  },
  "& .MuiDialogContent-root": {
    borderTop: `0px solid #F2F4F7`,
  },

  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function FileUpload({ setOpen, open, uploadProgress }) {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      setOpen(false);
      setActiveStep(0);
    }
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          sx={{
            fontSize: "22px",
          }}
        >
          Add Own Content{" "}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Box mt={10} sx={{ marginBottom: 10 }}>
            <Typography variant="h5" gutterBottom>
              Please wait, your content are being saved and files are being
              uploaded. This may take a few moments.
            </Typography>
            {Object.keys(uploadProgress).map((key) => (
              <div key={key}>
                File {parseInt(key) + 1}:{" "}
                <LinearProgress
                  variant="determinate"
                  value={uploadProgress[key]}
                />{" "}
                {uploadProgress[key]}%
              </div>
            ))}
          </Box>
        </DialogContent>

        <DialogActions></DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  // userloading: state.users.userloading,
  //userData: state.users.userData,
});

const mapDispatchToProps = (dispatch) => ({
  //  createTasks: (data, callback) => dispatch(crea(data, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(FileUpload);
