// actions.js

import {
  taskDataTriggered,
  taskDataSuccess,
  taskListhSuccess,
  assigntaskListSuccess,
  assigntaskListTriggered,
  contentTriggered,
  contentSuccess,
  psgetTriggered,
  psSuccess,
  accountTrigger,
  accountlistSuccess,
} from "./taskSlices";

import {
  GET_TASK,
  Add_Task,
  GET_ASSIGNEDTASK,
  GET_TASK_BYID,
  GET_CONTENT,
  UPDATE_STATUS,
  Add_PS,
  GET_PS,
  GET_ACCOUNT,
  Add_Invoice,
  POST_Revise_Content,
} from "./taskAPI";

export const gettask = (params) => async (dispatch) => {
  try {
    dispatch(taskDataTriggered());
    const response = await GET_TASK(params);
    if (response.status === 200) {
      dispatch(taskDataSuccess(response.data.data));
    } else if (response.ok === false) {
    }
  } catch (error) {
    console.log(error);
  }
};

export const gettasklist = (params) => async (dispatch) => {
  try {
    dispatch(taskDataTriggered());
    const response = await GET_TASK(params);
    if (response.status === 200) {
      dispatch(taskListhSuccess(response.data.data));
    } else if (response.ok === false) {
    }
  } catch (error) {
    console.log(error);
  }
};

export const getassignedtasklist = (params) => async (dispatch) => {
  try {
    dispatch(assigntaskListTriggered());
    const response = await GET_ASSIGNEDTASK(params);
    if (response.status === 200) {
      dispatch(assigntaskListSuccess(response.data));
    } else if (response.ok === false) {
    }
  } catch (error) {
    console.log(error);
  }
};

export const gettaskbyid = (params) => async (dispatch) => {
  try {
    //dispatch(taskbyidTriggered());
    const response = await GET_TASK_BYID(params);
    if (response.status === 200) {
      // dispatch(taskbyidSuccess(response.data));
    } else if (response.ok === false) {
    }
  } catch (error) {
    console.log(error);
  }
};

export const createTasks = (params, callback) => async (dispatch) => {
  try {
    const response = await Add_Task(params);
    if (response.status === 200) {
      callback({ err: false, data: response.data });
    } else {
      callback({ err: true, msg: response?.response?.data?.message });
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateTask = (params, id, callback) => async (dispatch) => {
  try {
    const response = await UPDATE_STATUS(params, id);
    if (response.status === 200) {
      callback({ err: false, data: response.data });
    } else {
      callback({ err: true, msg: response?.response?.data?.message });
    }
  } catch (error) {
    console.log(error);
  }
};
export const getContent = (params) => async (dispatch) => {
  try {
    dispatch(contentTriggered());
    const response = await GET_CONTENT(params);
    if (response.status === 200) {
      dispatch(contentSuccess(response.data));
    } else if (response.ok === false) {
    }
  } catch (error) {
    console.log(error);
  }
};

export const addPs = (params, id, callback) => async (dispatch) => {
  try {
    const response = await Add_PS(params, id);
    if (response.status === 200) {
      callback({ err: false, data: response.data });
    } else {
      callback({ err: true, msg: response?.response?.data?.message });
    }
  } catch (error) {
    console.log(error);
  }
};

export const getPS = (params) => async (dispatch) => {
  try {
    dispatch(psgetTriggered());
    const response = await GET_PS(params);
    if (response.status === 200) {
      dispatch(psSuccess(response.data));
    } else if (response.ok === false) {
    }
  } catch (error) {
    console.log(error);
  }
};

export const getAccounts = (params) => async (dispatch) => {
  try {
    dispatch(accountTrigger());
    const response = await GET_ACCOUNT(params);
    if (response.status === 200) {
      console.log("REDS,c", response);
      dispatch(accountlistSuccess(response.data));
    } else if (response.ok === false) {
    }
  } catch (error) {
    console.log(error);
  }
};

export const addInvoice = (params, callback) => async (dispatch) => {
  try {
    const response = await Add_Invoice(params);
    if (response.status === 200) {
      callback({ err: false, data: response.data });
    } else {
      callback({ err: true, msg: response?.response?.data?.message });
    }
  } catch (error) {
    console.log(error);
  }
};

export const reviseTasks = (params, id, callback) => async (dispatch) => {
  try {
    const response = await POST_Revise_Content(params, id);
    if (response.status === 200) {
      callback({ err: false, data: response.data });
    } else {
      callback({ err: true, msg: response?.response?.data?.message });
    }
  } catch (error) {
    console.log(error);
  }
};
