import React, { useEffect, useState } from "react";
import Header from "common/header/header";
import Footer from "common/footer";
import Background from "common/Background";
import {
  Grid,
  Typography,
  FormControl,
  FormHelperText,
  Button,
  Stack,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import {
  loginSchema,
  passwordSchema,
  registerValidationSchema,
  resetSchema,
} from "utils/schema";
import { useLocation, useNavigate } from "react-router-dom";
import TextInput from "common/TextInput";
import {
  forgotPasswordCall,
  logIn,
  setPasswordCall,
} from "store/auth/authActions";
import { connect } from "react-redux";

function SetPassword({ setPasswordc }) {
  const navigate = useNavigate();
  let query = new URLSearchParams(useLocation().search);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const formik = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: passwordSchema,
    onSubmit: async (values, helpers) => {
      try {
        setLoading(true);
        setError("");
        let email = query.get("email");
        let code = query.get("code");
        setPasswordc(
          {
            email,
            code,
            ...values,
          },
          async (res) => {
            setLoading(false);
            if (!res?.err) {
              alert(res?.data?.data);
              navigate("/login");
            }
            if (res?.err) {
              setError(res?.msg);
            }
          }
        );
      } catch (err) {
        setLoading(false);
      }
    },
  });

  return (
    <div className="bg_wrapper">
      <Background>
        <div className="white-box">
          <Grid
            container
            minHeight={"650px"}
            sx={{
              pt: { xs: "10px", md: "25px", lg: "80px" },
              pb: { xs: "10px", md: "50px", lg: "50px" },
              pr: { xs: "10px", md: "55px", lg: "100px" },
              pl: { xs: "10px", md: "40px", lg: "40px" },
              backgroundImage: `url('img/back4.svg')`,
              backgroundPosition: `center center`,
              backgroundSize: "contain",
              borderRadius: `21.529px`,
              position: "relative",
            }}
          >
            <Grid
              lg={6}
              md={6}
              alignItems={"flex-start"}
              sx={{ margin: { xs: "0 auto" } }}
            >
              <img src="img/left.png" className="register-box" />
            </Grid>
            <Grid
              lg={6}
              md={6}
              sx={{
                textAlign: "left",
                mt: { xs: 5, lg: 0 },
                pl: { md: 0, lg: 10 },
              }}
            >
              <Typography variant="title">Set your password</Typography>
              <Grid sx={{ pl: { lg: 4, md: 4 } }}>
                <Typography variant="subtitle1" sx={{ mt: 5 }}></Typography>

                <Typography variant="subtitle3" sx={{ mb: 5 }}>
                  Enter New password.
                </Typography>
                <form
                  noValidate
                  onSubmit={formik.handleSubmit}
                  style={{ marginTop: "25px" }}
                >
                  <TextInput
                    style={{ backgroundColor: "white" }}
                    type="password"
                    placeholder="Enter password"
                    label={"Password"}
                    name="password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    error={
                      formik.touched.password ? formik.errors.password : ""
                    }
                  />
                  {error != "" && (
                    <Typography className="text-danger">{error}</Typography>
                  )}
                  <LoadingButton
                    loading={loading}
                    fullWidth
                    size="large"
                    sx={{ mt: 10 }}
                    type="submit"
                    variant="contained"
                  >
                    Set New Password{" "}
                  </LoadingButton>{" "}
                </form>
                <br />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Background>
    </div>
  );
}

const mapStateToProps = (state) => ({
  registerData: state.auth.registerData,
});

const mapDispatchToProps = (dispatch) => ({
  setPasswordc: (data, callback) => dispatch(setPasswordCall(data, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(SetPassword);
